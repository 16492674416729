import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiService } from '../../../../services';
import { get } from '../../../../utils/object';
import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';
import InfoboxOverlay from '../../../../components/InfoboxOverlay';
import { addAlert, dismissAlert } from '../../../../actions/ui';

export class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      emailConfirm: '',
      emailPassword: '',
      isPasswordVisible: false,
      email: get(props.user, 'data.attributes.mail', '')
    };

    this.state = {
      ...this.initialState
    };
  }

  setAlerts(alert) {
    const { addAlert, dismissAlert } = this.props;

    addAlert(alert);

    setTimeout(() => dismissAlert(alert.id), 4000);

    if (alert.type === 'success') {
      this.resetForm();
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  resetForm = () => {
    this.setState({
      ...this.initialState
    });
  };

  changeEmail = async () => {
    const { user } = this.props;
    try {
      await this.props
        .setUserEmail(user.data.id, this.state.email, this.state.emailPassword)
        .then(action =>
          action.error
            ? this.setAlerts({
                id: 'setEmailError',
                header: 'Request failed!',
                info: 'The email did not change. Try again or contact support!',
                type: 'error'
              })
            : this.setAlerts({
                id: 'setEmailSuccess',
                header: 'Request successful!',
                info: 'Your email has been changed!',
                type: 'success'
              })
        );
    } catch (e) {
      console.log(e);
    }
  };

  handleEmailSubmit = () => {
    const { email, emailConfirm } = this.state;
    if (email !== '' && email === emailConfirm) {
      this.changeEmail();
    } else {
      this.setAlerts({
        id: 'setEmailError',
        header: 'Request failed!',
        info: 'The email did not change. No input or uncorrect confirmation!',
        type: 'error'
      });
    }
  };

  togglePassword = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  render() {
    const {
      email,
      emailConfirm,
      emailPassword,
      isPasswordVisible
    } = this.state;

    const passwordInputType = isPasswordVisible ? 'text' : 'password';

    return (
      <InfoboxOverlay>
        {() => (
          <div>
            <div className="flex w-full items-baseline">
              <h1 className="mt-8 w-1/5 font-bold text-xl"> Email</h1>
              {/* Alert info */}
              <div className="inline-block w-2/5 items-center mt-4">
                {/* {<Alert />} */}
              </div>
            </div>
            {/* Email */}
            <div className="mt-8 w-full flex">
              <div className="inline-block w-1/5">Enter email:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="email"
                  type="email"
                  size="md"
                  confirm={email === emailConfirm && email !== ''}
                  placeholder={'Enter email'}
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* Confirm email */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5">Confirm email:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="emailConfirm"
                  type="email"
                  size="md"
                  confirm={email === emailConfirm && email !== ''}
                  placeholder={'Enter email'}
                  value={emailConfirm}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* Passwort */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5">Enter password:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="emailPassword"
                  type={passwordInputType}
                  size="md"
                  placeholder="*****"
                  value={emailPassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* submit & show password checkbox */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5"></div>
              <div className="inline-block w-2/5 flex items-center justify-between">
                <div className="text-xs inline-block">
                  <label>
                    <input
                      id="showPassword"
                      type="checkbox"
                      onChange={this.togglePassword}
                      checked={this.state.isPasswordVisible}
                    />
                    <span className="ml-2">show password</span>
                  </label>
                </div>
                <div className="inline-block w-1/3">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={this.handleEmailSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

EmailForm.propTypes = {
  setUserEmail: PropTypes.func.isRequired,
  user: PropTypes.object,
  children: PropTypes.node,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, {
  setUserEmail: apiService.setUserEmail,
  addAlert,
  dismissAlert
})(EmailForm);
