import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { fetchGenotypesWorklist } from '../../../actions/genotypes';
import { addAlert, dismissAlert } from '../../../actions/ui';
import InfoboxOverlay from '../../../components/InfoboxOverlay';
import DataTable from '../../../components/DataTable';

export class GenoPhenoWorklist extends Component {
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { error, addAlert, dismissAlert, isFetching } = this.props;

    if (error && prevProps.error !== error) {
      addAlert({
        id: 'genoPhenoWorklistRequestError',
        header: 'Request failed!',
        info: 'Please reload your page or contact support!',
        type: 'error'
      });

      setTimeout(() => dismissAlert('genoPhenoWorklistRequestError'), 4000);
    } else if (!error && !isFetching) {
      addAlert({
        id: 'genoPhenoWorklistRequestSuccess',
        header: 'Request successful!',
        info: 'Your worklist has been updated!',
        type: 'success'
      });
      setTimeout(() => dismissAlert('genoPhenoWorklistRequestSuccess'), 4000);
    }
  }

  loadData = () => {
    this.props.fetchGenotypesWorklist();
  };

  handleEdit = id => {
    const { history } = this.props;
    history.push({
      pathname: '/variant-manager/edit-genotype',
      search: '?genotypeUuid=' + id
    });
  };

  getTableHeaders = () => {
    return [
      { label: 'Gene', id: 'associated_gene_id' },
      { label: 'Genotype', id: 'haplotype_name' },
      { label: 'Phenotype', id: 'phenotype_name' },
      { label: 'Status', id: 'l_phenotype_haplotype_wf_status_label' },
      {
        label: 'Last update',
        id: 'l_phenotype_haplotype_wf_status_update_formatted'
      }
    ];
  };

  getOptions = () => {
    return [
      {
        id: 'editGenotype',
        label: 'Edit',
        func: id => this.handleEdit(id)
      }
    ];
  };

  render() {
    const { genotypes, isFetching } = this.props;

    return (
      <InfoboxOverlay>
        {() => (
          <div className="ml-4 mr-4">
            <div className="px-2 py-4 text-xl font-semibold">
              <h1>Evaluation worklist</h1>
            </div>
            <hr className="mb-4 shadow" />
            <div>
              <DataTable
                headers={this.getTableHeaders()}
                data={genotypes.byId}
                keys={genotypes.allIds}
                options={this.getOptions()}
                isFetching={isFetching}
              />
            </div>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

GenoPhenoWorklist.defaultProps = {};

GenoPhenoWorklist.propTypes = {
  fetchGenotypesWorklist: PropTypes.func.isRequired,
  genotypes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    genotypes: state.genotypes.genotypes,
    error: state.genotypes.error,
    isFetching: state.genotypes.genotypes.isFetching
  };
};

export default connect(mapStateToProps, { fetchGenotypesWorklist, addAlert, dismissAlert })(GenoPhenoWorklist);
