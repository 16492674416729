import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toggleSidebarNav } from '../../actions/ui';

import { useLocation, useParams } from 'react-router-dom';

const PageWithNav = (props) => {
  // static propTypes = {
  //   children: PropTypes.func.isRequired,
  //   nav: PropTypes.func.isRequired,
  //   toggleSidebarNav: PropTypes.func.isRequired,
  //   on: PropTypes.bool.isRequired,
  //   name: PropTypes.string,
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
  // };
  const location = useLocation();
  const path = location.pathname;
  const params = useParams();

  const { children, name, nav, on, match, history, toggleSidebarNav } = props;

  const toggle = () => {
    toggleSidebarNav();
  };

    console.log(location)
    const sidebarClass = classNames(
      'flex flex-col justify-between top-16 bottom-0 left-0 text-left bg-gray-200 border-r border-gray-300 z-20',
      {
        'w-64': on,
        'w-20': !on
      }
    );

    return (
      <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr'}}>
        <div className={sidebarClass} style={{height: "calc(100vh - 64px)"}}>
          <div className="flex-1 overflow-y-auto overflow-x-hidden">
            <div className="flex p-4 items-center">
              <div className="flex flex-shrink-0 w-12 h-12 rounded-md bg-gray-400 items-center justify-center font-bold">
                {name.slice(0, 1)}
              </div>
              {on && <h2 className="text-md font-bold p-2">{name}</h2>}
            </div>
            <div>
              {nav({
                on: on,
                router: { match, location, history }, path, params
              })}
            </div>
          </div>
          <button
            className="text-center focus:outline-none p-4 border-t border-gray-500 text-gray-600 text-left"
            onClick={toggle}
          >
            {on ? '<< Collapse sidebar' : '>>'}
          </button>
        </div>
        <div className="flex-1">
          {children({
            router: { match, location, history } , path, params
          })}
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  console.log(state); // Log to ensure Redux state is as expected

  return {
    on: state.ui.showSidebar
  };
};

const mapDispatchToProps = {
  toggleSidebarNav,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageWithNav);
