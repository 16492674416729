import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Toggle from '../Toggle';
import Button from '../Button';

const Modal = ({
  label,
  header,
  children,
  onModalOpen,
  disabled = false,
  ...rest
}) => {
  const classnames = {
    modal:
      'fixed z-30 opacity-100 bg-white text-black rounded overflow-y-auto shadow-md w-2/3 max-h-3/4 shadow-xl',
    modalBackground: 'fixed z-20 inset-0 overflow-hidden bg-black opacity-50',
    wrapper: 'absolute inset-0 flex justify-center items-center overflow-hidden'
  };

  return (
    <Toggle>
      {({ on, toggle }) => {
        return (
          <div className="block">
            <Button
              size="xs"
              type="button"
              disabled={disabled}
              onClick={() => {
                toggle();
                onModalOpen && onModalOpen();
              }}
              {...rest}
            >
              {label}
            </Button>
            {on && (
              <div>
                <div className={classnames.modalBackground} onClick={toggle} />
                <div className={classnames.wrapper}>
                  <div className={classnames.modal}>
                    <div className="flex items-center justify-between bg-blue-800 rounded-t py-4 text-white">
                      <div>{header()}</div>
                      <div>
                        <span
                          className="absolute top-0 right-0"
                          onClick={toggle}
                        >
                          <svg
                            className="fill-current h-6 w-6 text-white hover:bg-white hover:text-blue-800 rounded"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="my-4 mx-8">
                      {children({ toggle: toggle })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Toggle>
  );
};

Modal.propTypes = {
  label: oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  children: PropTypes.func.isRequired,
  header: PropTypes.func.isRequired,
  className: PropTypes.string,
  onModalOpen: PropTypes.func
};

export default Modal;
