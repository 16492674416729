import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormDown } from 'grommet-icons';

import Button from '../Button';

export class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeDropdownOnAction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeDropdownOnAction, false);
  }

  closeDropdownOnAction = event => {
    if (event.code === 'Escape') {
      this.setState({ open: false });
    }
  };

  render() {
    const { open } = this.state;
    const { label, children, color, size } = this.props;
    return (
      <div>
        <Button
          color={color}
          size={size}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className="flex justify-between pl-2">
            <div className="pr-2">{label}</div>
            <div>
              <FormDown className="stroke-current" />
            </div>
          </div>
        </Button>
        {open && (
          <div>
            <div
              onClick={() => {
                this.setState({ open: false });
              }}
              className="fixed bg-gray-700 inset-0 opacity-25 z-40"
            />
            <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
              <div className="rounded-md bg-white shadow-xs">{children}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Dropdown.defaultProps = {};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  color: PropTypes.string,
  size: PropTypes.string
};

export default Dropdown;
