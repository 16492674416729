import React from 'react';
import { PropTypes } from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const RadioButton = ({ field, id, optionLabel, ...props }) => {
  return (
    <FormGroup check>
      <Label check>
        <Input
          type="radio"
          name={field.name}
          id={id}
          value={id}
          checked={id === field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
        {optionLabel}
      </Label>
    </FormGroup>
  );
};

RadioButton.propTypes = {
  field: PropTypes.object,
  id: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired
};
