import React from 'react';
import { PropTypes } from 'prop-types';
import TextInput from '../TextInput';

const DateRangePicker = ({ start, end, size, ...others }) => {
  return (
    <div className="flex justify-between items-center">
      <label htmlFor="startDate" className="mr-2">
        Start:
      </label>
      <TextInput
        id="startDate"
        type="date"
        size={size}
        value={start}
        {...others}
      />
      <label className="ml-4 mr-2" htmlFor="endDate">
        End:
      </label>
      <TextInput id="endDate" type="date" size={size} value={end} {...others} />
    </div>
  );
};

DateRangePicker.defaultProps = {};

DateRangePicker.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

export default DateRangePicker;
