import {
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_ERROR
} from '../actions/patients';
import dayjs from 'dayjs';

const initState = {
  data: [],
  isFetching: false,
  error: false
};

function normalizeState(array) {
  let data = [];

  if (array && array.length > 0) {
    data = array.map(item => {
      const { patient_id, created, ...rest } = item;

      // transform date format
      return {
        id: patient_id,
        created_formatted: dayjs
          .unix(created)
          .format('ddd, DD/MM/YYYY - HH:mm'),
        created: created,
        ...rest
      };
    });
  }

  return data;
}

const patientsReducer = (state = initState, action) => {
  switch (action.type) {
    case PATIENT_LIST_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true
      };
    case PATIENT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: normalizeState(action.payload.results),
        pager: action.payload.pager
      };
    case PATIENT_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default patientsReducer;
