import React, { Component } from 'react';
import PropTypes from 'prop-types';
import settings from '../../../../utils/settings';
import logo from '../../../../images/bio_Logo_GIMS-4c.jpg';
import { StatusCritical } from 'grommet-icons';

import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import TextInput from '../../../../components/TextInput';

export class LogInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username || '',
      password: '',
      isPasswordVisible: false
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    this.setState({
      isFormSubmitted: true
    });
    const { username, password } = this.state;
    this.props.logIn(username, password, this.props.queryParams);
  };

  togglePassword = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  render() {
    const { isLoading, error } = this.props;
    const { isPasswordVisible, username, password } = this.state;

    const passwordInputType = isPasswordVisible ? 'text' : 'password';

    return (
      <div className="w-full lg:w-3/4 max-w-screen-lg flex items-center border border-gray-400 px-16 bg-white rounded-lg shadow-2xl min-w-1000">
        {/* Logo */}
        <div className="inline-block">
          <img
            className="w-64"
            alt={settings.getSetting('site_name')}
            src={logo}
          />
        </div>

        {/* LogIn form */}
        <div className="w-full ml-8">
          <form
            className="bg-white rounded px-8 py-6"
            onSubmit={this.handleSubmit}
          >
            <div className="mb-4">
              <Label for="username">Username</Label>
              <TextInput
                size="md"
                id="username"
                value={username}
                placeholder="Username"
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-6">
              <div>
                <Label for="password">Password</Label>
                <TextInput
                  size="md"
                  id="password"
                  type={passwordInputType}
                  value={password}
                  placeholder="*****"
                  onChange={this.handleChange}
                />
              </div>
              <div className="text-xs ml-2 mt-2">
                <Label for="showPassword">
                  <input
                    id="showPassword"
                    type="checkbox"
                    onChange={this.togglePassword}
                    checked={isPasswordVisible}
                  />
                  <span className="ml-2">show password</span>
                </Label>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="w-1/5">
                <Button color="primary" type="submit" isLoading={isLoading}>
                  Sign In
                </Button>
              </div>

              {/* Error info */}
              {error && (
                <div
                  className={
                    'border-l-2 border-red-700 bg-red-100 items-center rounded-r shadow-lg w-3/5 flex justify-around'
                  }
                >
                  <div className="ml-6">
                    <StatusCritical
                      size="medium"
                      className="text-red-700 stroke-current"
                    />
                  </div>
                  <div className="mx-4">
                    <div className="pb-1 pt-2 text-red-700 font-semibold flex items-center">
                      <span className="">Login failed!</span>
                    </div>
                    <hr className="shadow border-red-100" />
                    <div className="py-2 font-hairline text-xs text-black">
                      Check username and password and try again!
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

LogInForm.propTypes = {
  logIn: PropTypes.func.isRequired,
  username: PropTypes.string,
  queryParams: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

export default LogInForm;
