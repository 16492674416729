export const TOGGLE_SIDEBARNAV = '@@ui/TOGGLE_SIDEBARNAV';
export const ADD_ALERT = '@@ui/ADD_ALERT';
export const DISMISS_ALERT = '@@ui/DISMISS_ALERT';

/* Allows opening general sidebar througout all screens */
export const toggleSidebarNav = () => ({
  type: TOGGLE_SIDEBARNAV
});

/* Add an alert to the current screen */
export function addAlert(newAlert) {
  return {
    type: ADD_ALERT,
    alert: newAlert
  };
}

/* Dismiss an alert of the current screen */
export function dismissAlert(idToDismiss) {
  return {
    type: DISMISS_ALERT,
    idToDismiss: idToDismiss
  };
}
