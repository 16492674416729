import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const Label = ({ for: htmlFor, className, children, ...others }) => {
  const classes = classNames(
    'block text-gray-700 text-sm font-bold mb-2',
    className
  );

  return (
    <label htmlFor={htmlFor} className={classes} {...others}>
      {children}
    </label>
  );
};

Label.defaultProps = {};

Label.propTypes = {
  for: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

export default Label;
