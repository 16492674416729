import PropTypes from 'prop-types';

export const getLanguage = () => {
  const defaultLang = 'en';

  // get language/locale from browser, fallback to default language
  let language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    defaultLang;
  // use lang without region locale

  language = language.split(/[-_]/)[0];

  return language;
};

// IntlShape is not included anymore as prop-type in react-intl v3+
// so we create our own
export const IntlShape = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
});
