import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { addAlert, dismissAlert } from '../../actions/ui';
import classNames from 'classnames';

export class InfoboxOverlay extends Component {
  getStyleOfAlertBox = type => {
    return classNames(
      'border-l-4 rounded-r px-4 py-2 my-2 shadow-lg flex items-center',
      {
        'border-red-700 bg-red-100': type === 'error',
        'border-green-700 bg-green-100': type === 'success'
      }
    );
  };

  getStyleOfAlertHeader = type => {
    return classNames('font-semibold', {
      'text-red-700': type === 'error',
      'text-green-700': type === 'success'
    });
  };

  getStyleOfAlertDivider = type => {
    return classNames('mt-1 mb-2 shadow', {
      'border-red-100': type === 'error',
      'border-green-100': type === 'success'
    });
  };

  getStyleOfAlertSvg = type => {
    return classNames('fill-current h-6 w-6 stroke-current', {
      'text-red-700': type === 'error',
      'text-green-700': type === 'success'
    });
  };

  displayCurrentAlerts = () => {
    const { alerts, dismissAlert } = this.props;

    return (
      <div className="fixed right-12 bottom-12 z-20 w-1/4">
        {alerts &&
          alerts.length > 0 &&
          alerts.map((alert, index) => {
            return (
              <div key={index} className={this.getStyleOfAlertBox(alert.type)}>
                <div className="w-3/4">
                  <div className={this.getStyleOfAlertHeader(alert.type)}>
                    {alert.header}
                  </div>
                  <hr className={this.getStyleOfAlertDivider(alert.type)} />
                  <div className="text-xs">{alert.info}</div>
                </div>
                <div className="w-1/4 flex justify-end">
                  <svg
                    className={this.getStyleOfAlertSvg(alert.type)}
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    onClick={() => {
                      dismissAlert(alerts, alert.id);
                    }}
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    const { children } = this.props;

    return (
      <div>
        {children()}
        {this.displayCurrentAlerts()}
      </div>
    );
  }
}

InfoboxOverlay.propTypes = {
  children: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    alerts: state.ui.alerts
  };
};

export default connect(mapStateToProps, { addAlert, dismissAlert })(
  InfoboxOverlay
);
