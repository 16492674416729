import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { apiService } from '../../../../services';
import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';
import InfoboxOverlay from '../../../../components/InfoboxOverlay';
import { addAlert, dismissAlert } from '../../../../actions/ui';

export class PasswordForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      isPasswordVisible: false
    };

    this.state = {
      ...this.initialState,
      isPasswordFormSubmitted: false
    };
  }

  setAlerts(alert) {
    const { addAlert, dismissAlert } = this.props;

    addAlert(alert);

    setTimeout(() => dismissAlert(alert.id), 4000);

    if (alert.type === 'success') {
      this.resetForm();
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  resetForm = () => {
    this.setState({
      ...this.initialState
    });
  };

  changePassword = async () => {
    const { user } = this.props;
    try {
      await this.props
        .changeUserPassword(
          user.data.id,
          this.state.currentPassword,
          this.state.newPassword
        )
        .then(action =>
          action.error
            ? this.setAlerts({
                id: 'setPasswordError',
                header: 'Request failed!',
                info:
                  'The password did not change. Try again or contact support!',
                type: 'error'
              })
            : this.setAlerts({
                id: 'setPasswordSuccess',
                header: 'Request successful!',
                info: 'Your password has been changed!',
                type: 'success'
              })
        );
    } catch (e) {
      console.log(e);
    }
  };

  handlePasswordSubmit = () => {
    const { newPassword, newPasswordConfirm } = this.state;
    if (newPassword !== '' && newPassword === newPasswordConfirm) {
      this.changePassword();
    } else {
      this.setAlerts({
        id: 'setPasswordError',
        header: 'Request failed!',
        info:
          'The password did not change. No input or uncorrect confirmation!',
        type: 'error'
      });
    }
  };

  togglePassword = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  render() {
    const { newPassword, newPasswordConfirm, isPasswordVisible } = this.state;

    const passwordInputType = isPasswordVisible ? 'text' : 'password';

    return (
      <InfoboxOverlay>
        {() => (
          <div>
            <div className="w-full flex items-baseline">
              <h1 className="mt-8 w-1/5 font-bold text-xl">Change password</h1>
              {/* Alert info */}
              <div className="inline-block w-2/5 items-center mt-4">
                {/* {<Alert />} */}
              </div>
            </div>
            {/* Old passwort */}
            <div className="mt-8 w-full flex">
              <div className="inline-block w-1/5">Old password:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="currentPassword"
                  type={passwordInputType}
                  size="md"
                  placeholder="*****"
                  value={this.state.currentPassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* New passwort */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5">New password:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="newPassword"
                  type={passwordInputType}
                  size="md"
                  confirm={
                    newPassword === newPasswordConfirm && newPassword !== ''
                  }
                  placeholder="*****"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* Confirm new passwort */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5">Confirm new password:</div>
              <div className="inline-block w-2/5">
                <TextInput
                  id="newPasswordConfirm"
                  type={passwordInputType}
                  size="md"
                  confirm={
                    newPassword === newPasswordConfirm && newPassword !== ''
                  }
                  placeholder="*****"
                  value={this.state.newPasswordConfirm}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* submit & show password checkbox */}
            <div className="mt-4 w-full flex">
              <div className="inline-block w-1/5"></div>
              <div className="inline-block w-2/5 flex items-center justify-between">
                <div className="text-xs inline-block">
                  <label>
                    <input
                      id="showPassword"
                      type="checkbox"
                      onChange={this.togglePassword}
                      checked={this.state.isPasswordVisible}
                    />
                    <span className="ml-2">show password</span>
                  </label>
                </div>
                <div className="inline-block w-1/3">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={this.handlePasswordSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

PasswordForm.propTypes = {
  user: PropTypes.object,
  changeUserPassword: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, {
  changeUserPassword: apiService.changeUserPassword,
  addAlert,
  dismissAlert
})(PasswordForm);
