import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import App from './screens/App';

import './css/index.css';

// react intl
import messages from './messages';
import { flattenMessages } from '@brightsolutionsgmbh/client-core';
import { getLanguage } from './utils/intl';
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'

// react-intl polyfills
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for de
}


const language = getLanguage();
const availableLocales = Object.keys(messages);

const Root = ({ store }) => (
  <Provider store={store}>
    <IntlProvider
      locale={language}
      messages={flattenMessages(
        messages[language] || messages[availableLocales[0]]
      )}
    >
      <App />
    </IntlProvider>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
