import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // import useNavigate
import PropTypes from 'prop-types';
import LogInForm from './LogInForm';
import { apiService } from '../../../services';

const LogIn = (props) => {
  const navigate = useNavigate(); // initialize the useNavigate hook

  const handleLogin = async (username, password, queryParams) => {
    try {
      const userAction = await props.logIn(username, password, queryParams);
      const { user } = props;
      if (userAction.ok) {
        const customerAction = await this.props.getCustomer(
          `/as_customer/as_customer/${user.relationships.field_customer.data.id}`
        );
        console.log(customerAction);

        if (
          !customerAction.error &&
          (
            (
              (((customerAction.payload || {}).data || {}).relationships || {})
                .drm_logo || {}
            ).data || {}
          ).id
        ) {
          await props.getFile(
            customerAction.payload.data.relationships.drm_logo.data.id
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const { isFetching, isLoggedIn, error, user } = props;

  debugger
  if (isLoggedIn && user.id) {
    navigate('/dashboard'); // use navigate instead of <Redirect>
    return null; // Return null or some placeholder here since we're not rendering a component
  }

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <LogInForm
        logIn={handleLogin}
        queryParams={{ language_content_entity: 'en' }}
        error={error}
        isLoading={isFetching}
      />
    </div>
  );
}

LogIn.propTypes = {
  logIn: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  getCustomer: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error.length > 0,
    user: state.user.data,
    isFetching: state.auth.isFetching
  };
};

export default connect(mapStateToProps, {
  logIn: apiService.logIn,
  getCustomer: apiService.getCustomer,
  getFile: apiService.getFile
})(LogIn);
