import {
  ALL_GENOTYPES_REQUEST,
  ALL_GENOTYPES_SUCCESS,
  ALL_GENOTYPES_ERROR,
  GENOTYPES_WORKLIST_REQUEST,
  GENOTYPES_WORKLIST_SUCCESS,
  GENOTYPES_WORKLIST_ERROR,
  GENOTYPE_REQUEST,
  GENOTYPE_SUCCESS,
  GENOTYPE_ERROR,
  ASSOCPHENOTYPES_REQUEST,
  ASSOCPHENOTYPES_SUCCESS,
  ASSOCPHENOTYPES_ERROR,
  POSSIBLE_WFSTATES_REQUEST,
  POSSIBLE_WFSTATES_SUCCESS,
  POSSIBLE_WFSTATES_ERROR,
  UPDATEGENOTYPE_REQUEST,
  UPDATEGENOTYPE_SUCCESS,
  UPDATEGENOTYPE_ERROR
} from '../actions/genotypes';
import dayjs from 'dayjs';

const initState = {
  allGenotypes: { byId: {}, allIds: [], isFetching: false },
  genotypes: { byId: {}, allIds: [], isFetching: false },
  activeGenotype: { genotype: {}, assocPhenotypes: [], isFetching: false },
  possibleWfStates: [],
  isFetching: false,
  error: false
};

function normalizeState(array, idUuid, single = false) {
  // TODO: Sync backend and frontend labels

  let byId = {}; // { uuid: element }
  let allIds = []; // array with uuids of all elements. Should be used to indicate ordering

  array.forEach(item => {
    const {
      [idUuid]: itemId,
      l_phenotype_haplotype_wf_status_id,
      l_phenotype_haplotype_wf_status_update,
      ...rest
    } = item;

    // labeling wf_status_id
    var wfState;
    switch (l_phenotype_haplotype_wf_status_id) {
      case '1':
        wfState = 'in progress';
        break;
      case '2':
        wfState = 'published';
        break;
      default:
        wfState = l_phenotype_haplotype_wf_status_id;
    }

    // transform date format
    let wfStateUpdate = dayjs(
      l_phenotype_haplotype_wf_status_update * 1000
    ).format('DD/MM/YYYY HH:mm:ss');

    byId[itemId] = {
      id: itemId,
      l_phenotype_haplotype_wf_status_id: l_phenotype_haplotype_wf_status_id,
      l_phenotype_haplotype_wf_status_update: l_phenotype_haplotype_wf_status_update,
      l_phenotype_haplotype_wf_status_label: wfState,
      l_phenotype_haplotype_wf_status_update_formatted: wfStateUpdate,
      ...rest
    };
    allIds.push(itemId);
  });

  if (single) {
    return byId[allIds[0]];
  } else {
    return { byId: byId, allIds: allIds, isFetching: false };
  }
}

function isError(payload) {
  if (payload.error || !payload.result) {
    return true;
  } else {
    return false;
  }
}

function normalizePossiblePhenotypes(array) {
  return array.map(item => {
    return {
      id: item.phenotype_endpoint_uuid,
      label: item.phenotype_name
    };
  });
}

function normalizeWfState(array) {
  return array.map(item => {
    var wfState;
    switch (item.id) {
      case '1':
        wfState = 'in progress';
        break;
      case '2':
        wfState = 'published';
        break;
      default:
        wfState = item.label;
    }
    return {
      id: item.id,
      labelBackend: item.label,
      label: wfState
    };
  });
}

const genotypesReducer = (state = initState, action) => {
  switch (action.type) {
    case ALL_GENOTYPES_REQUEST:
      return {
        ...state,
        allGenotypes: { ...state.allGenotypes, isFetching: true },
        error: false,
        isFetching: true
      };
    case GENOTYPES_WORKLIST_REQUEST:
      return {
        ...state,
        genotypes: { ...state.genotypes, isFetching: true },
        error: false,
        isFetching: true
      };
    case GENOTYPE_REQUEST:
      return {
        ...state,
        activeGenotype: { ...state.activeGenotype, isFetching: true },
        error: false,
        isFetching: true
      };
    case ASSOCPHENOTYPES_REQUEST:
    case UPDATEGENOTYPE_REQUEST:
    case POSSIBLE_WFSTATES_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true
      };
    case ALL_GENOTYPES_ERROR:
      return {
        ...state,
        allGenotypes: { ...state.allGenotypes, isFetching: false },
        isFetching: false,
        error: true
      };
    case GENOTYPES_WORKLIST_ERROR:
      return {
        ...state,
        genotypes: { ...state.genotypes, isFetching: false },
        isFetching: false,
        error: true
      };
    case GENOTYPE_ERROR:
      return {
        ...state,
        activeGenotype: { ...state.activeGenotype, isFetching: false },
        isFetching: false,
        error: true
      };
    case POSSIBLE_WFSTATES_ERROR:
    case ASSOCPHENOTYPES_ERROR:
    case UPDATEGENOTYPE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    case ALL_GENOTYPES_SUCCESS:
      if (!isError(action.payload)) {
        return {
          ...state,
          isFetching: false,
          allGenotypes: normalizeState(
            action.payload.result,
            'haplotype_endpoint_uuid'
          )
        };
      } else {
        return {
          ...state,
          allGenotypes: { ...state.allGenotypes, isFetching: false },
          isFetching: false,
          error: true
        };
      }
    case GENOTYPES_WORKLIST_SUCCESS:
      if (!isError(action.payload)) {
        return {
          ...state,
          isFetching: false,
          genotypes: normalizeState(
            action.payload.result,
            'haplotype_endpoint_uuid'
          )
        };
      } else {
        return {
          ...state,
          genotypes: { ...state.genotypes, isFetching: false },
          isFetching: false,
          error: true
        };
      }
    case GENOTYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeGenotype: {
          ...state.activeGenotype,
          isFetching: false,
          genotype: normalizeState(
            action.payload.result,
            'haplotype_endpoint_uuid',
            true
          )
        }
      };
    case ASSOCPHENOTYPES_SUCCESS:
      if (!isError(action.payload)) {
        return {
          ...state,
          isFetching: false,
          activeGenotype: {
            ...state.activeGenotype,
            assocPhenotypes: normalizePossiblePhenotypes(action.payload.result)
          }
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: true
        };
      }
    case POSSIBLE_WFSTATES_SUCCESS:
      if (!isError(action.payload)) {
        return {
          ...state,
          isFetching: false,
          possibleWfStates: normalizeWfState(action.payload.result)
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: true
        };
      }

    case UPDATEGENOTYPE_SUCCESS: {
      if (!isError(action.payload)) {
        const updatedGenotype = normalizeState(
          action.payload.result,
          'haplotype_endpoint_uuid'
        ).byId[action.payload.result[0].haplotype_endpoint_uuid];
        const { id } = updatedGenotype;
        return {
          ...state,
          isFetching: false,
          genotypes: {
            ...state.genotypes,
            isFetching: false,
            byId: { ...state.genotypes.byId, [id]: updatedGenotype }
          },
          allGenotypes: {
            ...state.allGenotypes,
            isFetching: false,
            byId: { ...state.genotypes.byId, [id]: updatedGenotype }
          }
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: true
        };
      }
    }

    default:
      return state;
  }
};

export default genotypesReducer;
