import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PasswordForm from './PasswordForm';
import EmailForm from './EmailForm';
import { get } from '../../../utils/object';

export class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user } = this.props;

    return (
      <div className="m-8">
        {/* Username */}
        <h1 className="w-full font-bold text-xl">Profile</h1>
        <div className="mt-8 w-full flex">
          <div className="inline-block w-1/5">Username:</div>
          <div className="inline-block w-2/5 ml-1">
            {get(user, 'data.attributes.display_name')}
          </div>
        </div>

        {/* Password */}
        <hr className="mt-8" />
        <PasswordForm />

        {/* Email */}
        <hr className="mt-8" />
        <EmailForm />
      </div>
    );
  }
}
export default Account;
