import React from 'react';
import { Field } from 'formik';
import { PropTypes } from 'prop-types';
import { RadioButton } from '../RadioButton';
import { Checkbox } from '../Checkbox';

export const FormField = ({ fieldData }) => {
  let field;

  if (fieldData.type === 'select') {
    field = (
      <Field
        component="select"
        name={fieldData.name}
        className="w-2/3 text-gray-700 bg-gray-200 leading-tight appearance-none rounded py-2 px-4 shadow focus:outline-none focus:shadow-outline"
      >
        {fieldData.options &&
          fieldData.options.map((option, index) => (
            <option key={index} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
      </Field>
    );
  } else if (fieldData.type === 'radios') {
    field = (
      <>
        {fieldData.options.map((option, index) => (
          <Field
            key={index}
            name={fieldData.name}
            id={option.value}
            label={fieldData.label}
            optionLabel={option.label}
            component={RadioButton}
          />
        ))}
      </>
    );
  } else if (fieldData.type === 'checkbox') {
    field = (
      <Field
        component={Checkbox}
        name={fieldData.name}
        id={fieldData.name}
        label={fieldData.label}
      />
    );
  } else {
    field = (
      <Field
        type={fieldData.type || 'text'}
        name={fieldData.name}
        id={fieldData.name}
        placeholder={fieldData.label}
        className="w-2/3 text-gray-700 bg-gray-200 leading-tight appearance-none rounded py-2 px-3 shadow focus:outline-none focus:shadow-outline"
      />
    );
  }

  return field;
};

FormField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
