import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function Dialog({ children, toggle, ...rest }) {
  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', toggle);
    }, 250);
    
    return () => {
      document.removeEventListener('click', toggle);
    };
  }, []);

  return <div {...rest}>{children}</div>;
}

Dialog.propTypes = {
  toggle: PropTypes.func,
  children: PropTypes.node
};

export default Dialog;
