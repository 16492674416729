import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getReportOrders } from '../../../actions/orders';
import { getReportOrder, setQuestions } from '../../../actions/report';
import { downloadFileFromBlob } from '@brightsolutionsgmbh/client-core';
import { apiService } from '../../../services';
import dayjs from 'dayjs';
import CustomTable from '../../../components/CustomTable';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TextInput from '../../../components/TextInput';
import DateRangePicker from '../../../components/DateRangePicker';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import Select from '../../../components/Select';
import SchemaForm from '../../../components/SchemaForm';
import Pagination from '../../../components/Pagination';
import {
  Descend,
  Ascend,
  StatusCritical,
  DocumentVerified,
  Download
} from 'grommet-icons';
import LoadingSpinner from '../../../components/LoadingSpinner';
import settings from '../../../utils/settings';

export class Orders extends Component {
  static loadingInterval = 5000;

  constructor(props) {
    super(props);

    this.state = {
      contentVariables: {},
      questionnaire: {},
      filter: {
        patientId: '',
        startDate: '',
        endDate: '',
        processingStatus: ''
      },
      sortField: 'request_timestamp',
      sortDirection: 'desc',
      submittedFilter: {},
      currentPage: 0,
      firstFetching: true
    };
  }

  componentDidMount() {
    this.dataPolling = setInterval(() => {
      this.loadData(this.state.submittedFilter, 0, this.state.sort);
      this.setState({ firstFetching: false });
    }, Orders.loadingInterval);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location &&
      prevProps.location.search !== this.props.location.search
    ) {
      this.onPageChange();
      clearInterval(this.dataPolling);
      this.dataPolling = setInterval(() => {
        this.loadData(
          this.state.submittedFilter,
          this.state.currentPage,
          this.state.sort
        );
      }, Orders.loadingInterval);
    }
  }

  componentWillUnmount() {
    if (this.dataPolling) {
      clearInterval(this.dataPolling);
    }
  }

  handleFilterChange = ev => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, [ev.target.id]: ev.target.value }
    });
  };

  onSortChange = sortField => {
    this.setState(
      prevState => ({
        sortField: sortField,
        sortDirection: prevState.sortDirection === 'desc' ? 'asc' : 'desc'
      }),
      () => this.handleSortChange(sortField, this.state.sortDirection)
    );
  };

  handleSortChange = (sortField, sortDirection) => {
    let mainSort = sortDirection === 'desc' ? '-' : '';
    mainSort = mainSort + sortField;
    // secondary sort always descending
    let secondarySort =
      '-' +
      (sortField === 'request_timestamp' ? 'patient_id' : 'request_timestamp');

    const sort = [mainSort, secondarySort];
    this.setState({ sort });
    this.loadData(this.state.filter, this.state.currentPage, sort);
  };

  handleFilterSubmit = () => {
    const {
      patientId,
      startDate,
      endDate,
      processingStatus
    } = this.state.filter;
    const filter = {
      patientId: patientId,
      startDate: startDate && dayjs(startDate),
      endDate: endDate && dayjs(endDate).add(86399, 'second'),
      processingStatus: processingStatus
    };

    this.setState({ submittedFilter: filter }, () =>
      this.loadData(filter, 0, this.state.sort)
    );
  };

  handleClickEnterOnFilter = target => {
    if (target.charCode === 13) {
      this.handleFilterSubmit();
    }
  };

  getContentVariables = async order => {
    try {
      let contentVariables = [];
      const response = await this.props.getReportOrder(
        order.id,
        'template_content_variables'
      );

      if (
        response.payload.data.attributes &&
        response.payload.data.attributes.template_content_variables
      ) {
        contentVariables = JSON.parse(
          response.payload.data.attributes.template_content_variables
        );
      }

      this.setState({ contentVariables });
    } catch (e) {
      console.log(e);
    }
  };

  getQuestions = async order => {
    try {
      let questionnaire = [];
      const response = await this.props.getReportOrder(
        order.id,
        'questionnaire'
      );

      if (
        response.payload.data.attributes &&
        response.payload.data.attributes.questionnaire
      ) {
        questionnaire = JSON.parse(
          response.payload.data.attributes.questionnaire
        );
      }

      this.setState({ questionnaire: questionnaire });
    } catch (e) {
      console.log(e);
    }
  };

  submitContentVariables = (values, actions, order, toggle) => {
    const { contentVariables, currentPage, submittedFilter, sort } = this.state;
    const filledContentVariables = [];

    Object.keys(contentVariables).forEach(key => {
      Object.keys(contentVariables[key].content_variables).forEach(k => {
        const contentVariable = contentVariables[key].content_variables[k];
        if (values[contentVariable.name]) {
          const variable = {};
          variable.key = contentVariable.name;
          variable.value = values[contentVariable.name];
          variable.type = contentVariable.type;
          filledContentVariables.push(variable);
        }
      });
    });

    this.createPrintJobs({ order, filledContentVariables }).then(() => {
      actions.setSubmitting(false);
      this.loadData(submittedFilter, currentPage, sort);
    });

    toggle();
  };

  submitQuestions = (values, order, process = false, toggle) => {
    this.props.setQuestions(values, order.id, process);
    toggle();
  };

  createPrintJobs = ({ order, filledContentVariables }) => {
    const { createPrintJob } = this.props;
    const printJobs = [];

    ((order.attributes || {}).print_job_sub_order_data || []).forEach(
      subOrder => {
        let printJob = createPrintJob({
          id: subOrder.target.target_id,
          targetType: subOrder.target.target_type,
          printType: subOrder.field_print_type,
          contentVariables: filledContentVariables,
          langCode: order.attributes.langcode
        });
        printJobs.push(printJob);
      }
    );

    return Promise.all(printJobs);
  };

  downloadFile = uri => {
    const { downloadFile } = this.props;
    const splittedUri = uri.split('/');
    const filename = splittedUri[splittedUri.length - 1];

    downloadFile(settings.getSetting('server_root') + '' + uri).then(action => {
      action.payload.blob().then(blob => downloadFileFromBlob(blob, filename));
    });
  };

  loadData = (filter, page, sort) => {
    if (!isNil(page)) {
      this.setState({ currentPage: page }, () =>
        this.props.getReportOrders(
          pickBy({ page: page, ...filter, sort }, identity)
        )
      );
    } else {
      this.props.getReportOrders(
        pickBy({ page: this.state.currentPage, ...filter, sort }, identity)
      );
    }
  };

  getSortingIcon = () => {
    if (this.state.sortDirection === 'desc') {
      return (
        <span className="ml-4">
          <Descend color="#0f73c2" />
        </span>
      );
    } else {
      return (
        <span className="ml-4">
          <Ascend color="#0f73c2" />
        </span>
      );
    }
  };

  getStatusIcon = status => {
    switch (status) {
      case 'ready':
      case 'report_delivered':
        return <DocumentVerified />;
      case 'pending':
      case 'rle_pending':
        return <LoadingSpinner color="black" />;
      case 'error':
      case 'error_delivered':
      case 'rle_error':
      case 'processing_time_limit_exceeded':
        return <StatusCritical />;
      default:
        return '';
    }
  };

  getGenerationStatusMessageString = status => {
    switch (status) {
      case 'report_delivered':
      case 'ready':
        return `generationStatusReady`;
      case 'pending':
        return `generationStatusPending`;
      case 'waiting_for_input':
        return `generationStatusWaitingForInput`;
      case 'error':
      case 'error_delivered':
        return `generationStatusReportError`;
      default:
        return 'null';
    }
  };

  getPersonalizationStatusMessageString = status => {
    switch (status) {
      case 'report_delivered':
        return `personalizationStatusReportDelivered`;
      case 'rle_pending':
      case 'pending':
        return `personalizationStatusPending`;
      case 'rle_error':
        return `personalizationStatusError`;
      default:
        return 'null';
    }
  };

  getOrdersFilter = changePage => {
    const { filter } = this.state;

    const generationStatusOptions = [
      {
        value: 'report_delivered',
        label: 'Report delivered'
      },
      {
        value: 'pending',
        label: 'Pending'
      },
      {
        value: 'error',
        label: 'Error'
      }
    ];
    return (
      <div
        className="flex"
        onKeyPress={e => {
          this.handleClickEnterOnFilter(e);
          changePage(0);
        }}
      >
        <div className="w-64">
          <TextInput
            id="patientId"
            value={filter.patientId}
            onChange={this.handleFilterChange}
            placeholder="Search patient id..."
          />
        </div>
        <div className="ml-4">
          <DateRangePicker
            size="sm"
            start={filter.startDate}
            end={filter.endDate}
            onChange={this.handleFilterChange}
          />
        </div>
        <div className="ml-4 w-48">
          <Select
            id="processingStatus"
            data={generationStatusOptions}
            idKey="value"
            idLabel="label"
            idValue="value"
            onChange={this.handleFilterChange}
            selected={this.state.filter.processingStatus}
            size="sm"
            placeholder="Generation status..."
          />
        </div>
        <div className="w-16 ml-4">
          <Button
            size="sm"
            type="button"
            onClick={() => {
              this.handleFilterSubmit();
              changePage(0);
            }}
          >
            <FormattedMessage id="tools.ssup.options.filter.name" />
          </Button>
        </div>
        <div className="w-16 ml-4">
          <Button
            size="sm"
            type="button"
            onClick={() => {
              this.setState({
                filter: {
                  patientId: '',
                  startDate: '',
                  endDate: '',
                  processingStatus: ''
                },
                submittedFilter: {}
              });
              this.loadData({});
            }}
          >
            <FormattedMessage id="tools.ssup.options.reset.name" />
          </Button>
        </div>
      </div>
    );
  };

  getOrdersTableHead = classnames => {
    const { sortField } = this.state;
    return (
      <ul className={classnames.header}>
        <li
          className={classnames.element}
          onClick={() => this.onSortChange('patient_id')}
        >
          <FormattedMessage id="tools.ssup.options.orders.props.patientId.name" />
          <span>{sortField === 'patient_id' && this.getSortingIcon()}</span>
        </li>
        <li
          className={classnames.element}
          onClick={() => this.onSortChange('request_timestamp')}
        >
          <FormattedMessage id="tools.ssup.options.orders.props.requestDate.name" />
          <span>
            {sortField === 'request_timestamp' && this.getSortingIcon()}
          </span>
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.generationStatus.name" />
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.personalization.name" />
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.personalizationStatus.name" />
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.report.name" />
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.medicationSafetyCard.name" />
        </li>
        <li className={classnames.element}>
          <FormattedMessage id="tools.ssup.options.orders.props.coverLetter.name" />
        </li>
      </ul>
    );
  };

  getOrdersTableBody = (order, classnames) => {
    const { contentVariables, questionnaire } = this.state;
    return (
      <div key={order.id} className={classnames.row}>
        <div className={classnames.element}>
          <div>{order.attributes.patient_id}</div>
          <div className="text-xs">{order.attributes.clinical_report}</div>
        </div>
        <div className={classnames.element}>
          {dayjs(order.attributes.request_timestamp).format(
            'YYYY-MM-DD HH:mm:ss'
          )}
        </div>
        <div className={classnames.element}>
          <div className="inline-block p-2 items-baseline">
            {this.getStatusIcon(order.attributes.combined_processing_status)}
          </div>
          <FormattedMessage
            id={`tools.ssup.options.orders.props.${this.getGenerationStatusMessageString(
              order.attributes.combined_processing_status
            )}`}
          />
        </div>
        <div className={classnames.element}>
          <div className="flex justify-center">
            <div className="w-16">
              {order.attributes.combined_processing_status ===
              'waiting_for_input' ? (
                <Modal
                  label={
                    <FormattedMessage id="tools.ssup.options.input.name" />
                  }
                  disabled={
                    order.attributes.combined_processing_status !==
                      'report_delivered' ||
                    order.attributes.print_job_combined_processing_status ===
                      'pending' ||
                    order.attributes.print_job_combined_processing_status ===
                      'rle_pending'
                  }
                  onModalOpen={() => this.getQuestions(order)}
                  header={() => (
                    <h1 className="py-2 px-4">
                      <span className="text-xs">
                        <FormattedMessage id="tools.ssup.options.orders.props.patientId.name" />
                        :
                      </span>
                      <span className="text-lg font-bold ml-4">
                        {order.attributes.patient_id}
                      </span>
                    </h1>
                  )}
                >
                  {({ toggle }) =>
                    questionnaire && (
                      <SchemaForm
                        submit={values =>
                          this.submitQuestions(values, order, toggle)
                        }
                        formFields={questionnaire}
                        renderButtons={props =>
                          this.getRenderButtons(props, order, toggle)
                        }
                      />
                    )
                  }
                </Modal>
              ) : (
                <Modal
                  label={
                    <FormattedMessage id="tools.ssup.options.input.name" />
                  }
                  disabled={
                    order.attributes.combined_processing_status !==
                      'report_delivered' ||
                    order.attributes.print_job_combined_processing_status ===
                      'pending' ||
                    order.attributes.print_job_combined_processing_status ===
                      'rle_pending'
                  }
                  onModalOpen={() => this.getContentVariables(order)}
                  header={() => (
                    <h1 className="py-2 px-4">
                      <span className="text-xs">
                        <FormattedMessage id="tools.ssup.options.orders.props.patientId.name" />
                        :
                      </span>
                      <span className="text-lg font-bold ml-4">
                        {order.attributes.patient_id}
                      </span>
                    </h1>
                  )}
                >
                  {({ toggle }) =>
                    contentVariables && (
                      <SchemaForm
                        submit={(values, actions) =>
                          this.submitContentVariables(
                            values,
                            actions,
                            order,
                            toggle
                          )
                        }
                        formFields={contentVariables}
                      />
                    )
                  }
                </Modal>
              )}
            </div>
          </div>
        </div>
        <div className={classnames.element}>
          <div className="inline-block p-2 items-baseline">
            {this.getStatusIcon(
              order.attributes.print_job_combined_processing_status
            )}
          </div>
          <FormattedMessage
            id={`tools.ssup.options.orders.props.${this.getPersonalizationStatusMessageString(
              order.attributes.print_job_combined_processing_status
            )}`}
          />
        </div>
        <div className={classnames.element}>
          {order.attributes.print_job_download_urls[0] &&
            order.attributes.print_job_download_urls[0].links.map((item, i) => (
              <div key={i} className="flex justify-center my-1">
                <div className="w-16">
                  <Button
                    size="xs"
                    onClick={() => this.downloadFile(item.link)}
                    type="button"
                  >
                    <Download color="white" size="small" />
                    <span className="ml-2">{item.type.toUpperCase()}</span>
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className={classnames.element}>
          {order.attributes.print_job_download_urls[1] &&
            order.attributes.print_job_download_urls[1].links.map((item, i) => (
              <div key={i} className="flex justify-center my-1">
                <div className="w-16">
                  <Button
                    size="xs"
                    onClick={() => this.downloadFile(item.link)}
                    type="button"
                  >
                    <Download color="white" size="small" />
                    <span className="ml-2">{item.type.toUpperCase()}</span>
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className={classnames.element}>
          {order.attributes.print_job_download_urls[2] &&
            order.attributes.print_job_download_urls[2].links.map((item, i) => (
              <div key={i} className="flex justify-center my-1">
                <div className="w-16">
                  <Button
                    size="xs"
                    onClick={() => this.downloadFile(item.link)}
                    type="button"
                  >
                    <Download color="white" size="small" />
                    <span className="ml-2">{item.type.toUpperCase()}</span>
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  getRenderButtons = ({ values, formFields }, order, toggleModal) => {
    let hideSendButton = false;
    Object.keys(formFields).forEach(formFieldKey => {
      formFields[formFieldKey].questions.forEach(question => {
        Object.keys(values).forEach(() => {
          if (question.required) {
            if (
              values[question.name] === '' ||
              values[question.name] === false
            ) {
              hideSendButton = true;
            }
          }
        });
      });
    });

    return (
      <form>
        <Button
          color="primary"
          onClick={() => this.submitQuestions(values, order, toggleModal)}
        >
          <FormattedMessage id="tools.ssup.options.save.name" />
        </Button>
        {!hideSendButton && (
          <Button
            color="primary"
            className="mx-2"
            onClick={() =>
              this.submitQuestions(values, order, toggleModal, true)
            }
          >
            <FormattedMessage id="tools.ssup.options.send.name" />
          </Button>
        )}
      </form>
    );
  };

  render() {
    const { orders, isFetching, pager } = this.props;
    const { submittedFilter, firstFetching, sort } = this.state;

    return (
      <div className="m-6">
        <Pagination
          pager={pager}
          loadData={this.loadData}
          filter={submittedFilter}
          loading={firstFetching || isFetching}
          sort={sort}
        >
          {({ changePage }) => (
            <div>
              {this.getOrdersFilter(changePage)}
              <hr className="m-4 shadow" />
              <CustomTable
                header={({ classnames }) => this.getOrdersTableHead(classnames)}
                body={({ classnames }) => {
                  if (orders && orders.length > 0) {
                    return orders.map(order => {
                      return this.getOrdersTableBody(order, classnames);
                    });
                  }
                }}
                stripped
                isEmpty={orders && orders.length === 0}
              />
            </div>
          )}
        </Pagination>
      </div>
    );
  }
}

Orders.defaultProps = {
  orders: []
};

Orders.propTypes = {
  location: PropTypes.object.isRequired,
  getReportOrders: PropTypes.func.isRequired,
  getReportOrder: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  downloadFile: PropTypes.func.isRequired,
  createPrintJob: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  pager: PropTypes.object
};

const mapStateToProps = state => {
  return {
    orders: state.orders.data.data,
    isFetching: state.orders.isFetching,
    pager: state.orders.data.meta && {
      count: state.orders.data.meta.count,
      pages: Math.floor(state.orders.data.meta.count % 10)
        ? Math.floor(state.orders.data.meta.count / 10) + 1
        : Math.floor(state.orders.data.meta.count / 10)
    }
  };
};

export default (
  connect(mapStateToProps, {
    getReportOrders,
    getReportOrder,
    setQuestions,
    downloadFile: apiService.downloadFile,
    createPrintJob: apiService.createPrintJob
  })(injectIntl(Orders))
);
