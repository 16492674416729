import { jsonapi } from '@brightsolutionsgmbh/client-core';

export const PRODUCTS_REQUEST = '@@patients/PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = '@@patients/PRODUCTS_SUCCESS';
export const PRODUCTS_ERROR = '@@patients/PRODUCTS_ERROR';

export const getProducts = () => {
  return jsonapi.request('GET', `/product/product`, [
    PRODUCTS_REQUEST,
    PRODUCTS_SUCCESS,
    PRODUCTS_ERROR
  ]);
};
