import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const LoadingSpinner = ({ className, color = 'white', ...others }) => {
  const classnames = classNames(
    {
      spinner: color === 'white',
      'spinner-black': color === 'black',
      'spinner-gray': color === 'gray'
    },
    className
  );

  return (
    <div className="w-10 h-auto relative">
      <span className={classnames} {...others}></span>
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default LoadingSpinner;
