import React, { Component } from 'react';
import { oneOfType } from 'prop-types';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Button from '../Button';
import OperationSelect from '../OperationSelect';
import TextInput from '../TextInput';
import Dropdown from '../Dropdown';
import LoadingSpinner from '../LoadingSpinner';

export class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayOrder: [],
      display: [],
      data: [],
      search: '',
      dragging: false,
      indexOfDraggedColumn: null,
      indexOfEnteredColumn: null,
      currentPage: 0
    };
  }

  componentDidMount() {
    const { headers } = this.props;
    this.setState({
      displayOrder: headers,
      display: headers,
      data: this.getFormattedData()
    });
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    /** only update state if data has changed */
    if (data && prevProps.data && this.dataHasChanged(data, prevProps.data)) {
      this.setState({
        data: this.getFormattedData()
      });
    }
  }

  /** checks if data has changed */
  dataHasChanged(data, oldData) {
    if (!Array.isArray(data) && !Array.isArray(oldData)) {
      let dataAsArray = Object.values(data);
      let oldDataAsArray = Object.values(oldData);

      return this.compareArraysForChanges(dataAsArray, oldDataAsArray);
    } else if (Array.isArray(data) && Array.isArray(oldData)) {
      return this.compareArraysForChanges(data, oldData);
    } else {
      return false;
    }
  }

  compareArraysForChanges = (arr1, arr2) => {
    let matchCount = 0;

    try {
      if (arr1.length === arr2.length) {
        for (let i of arr1) {
          for (let j of arr2) {
            if (JSON.stringify(i) === JSON.stringify(j)) {
              matchCount++;
            }
          }
        }
        if (matchCount === arr1.length) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.log(error)
    }

  };

  /* format objects to arrays with its keys */
  getFormattedData = () => {
    const { data, keys } = this.props;

    if (data instanceof Object && !Array.isArray(data)) {
      if (keys && keys.length > 0) {
        console.log(keys);
        return Object.values(data);
      }
    } else if (Array.isArray(data)) {
      return [...data];
    }

    // Default return value when none of the conditions are met.
    return [];
  };

  getStyleOfHeader = index => {
    const { indexOfDraggedColumn, indexOfEnteredColumn } = this.state;
    const classnames = classNames('h-16', {
      'bg-gradient-to-r from-indigo-200 to-gray-100 border-l-2 border-indigo-200 text-center truncate':
        indexOfEnteredColumn === index && indexOfDraggedColumn >= index,
      'bg-gradient-to-l from-indigo-200 to-gray-100 border-r-2 border-indigo-200 text-center truncate':
        indexOfEnteredColumn === index && indexOfDraggedColumn < index,
      'border-b border-gray-200 bg-gray-100 text-center truncate border-r border-gray-200':
        indexOfEnteredColumn !== index
    });
    return classnames;
  };

  /* render all headers, draggable */
  mapHeaders = () => {
    const { display } = this.state;
    const { options } = this.props;

    if (display && display.length > 0) {
      return (
        <tr className="text-center">
          {display.map((header, index) => {
            return (
              <th
                key={index}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDragEnter={e => {
                  if (this.state.dragging) {
                    this.setState({ indexOfEnteredColumn: index });
                  }
                  e.preventDefault();
                }}
                onDragEnd={e => {
                  e.preventDefault();
                  this.setState({
                    indexOfEnteredColumn: null,
                    indexOfDraggedColumn: null
                  });
                }}
                onDrop={e => {
                  e.preventDefault();
                  this.setState({
                    indexOfEnteredColumn: null,
                    indexOfDraggedColumn: null
                  });
                  let data = JSON.parse(e.dataTransfer.getData('columnHeader'));
                  if (data) {
                    return this.handleColumnDrop(header, data);
                  }
                }}
                className={this.getStyleOfHeader(index)}
              >
                <label
                  draggable
                  onDragStart={e => {
                    this.setState({
                      dragging: true,
                      indexOfDraggedColumn: index
                    });
                    e.dataTransfer.setData(
                      'columnHeader',
                      JSON.stringify(header)
                    );
                  }}
                  className="m-2 text-gray-800 bg-gradient-to-t hover:from-gray-300 px-8 py-2 rounded-lg cursor-move select-none border-l-2 border-b-2 border-gray-300"
                >
                  {header.label}
                </label>
              </th>
            );
          })}

          {/* adds optional options column  */}
          {options && options.length > 0 && (
            <th className="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">
              <label className="text-gray-800 inline-flex justify-between items-center px-2 py-2 rounded-full">
                Options
              </label>
            </th>
          )}
        </tr>
      );
    }
  };

  /* used for column drag: change index of dragged column in display order */
  array_move = (arr, oldIndex, newIndex) => {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /* get indices of dragged and target components to change order */
  handleColumnDrop = (targetHeader, draggedHeader) => {
    const { displayOrder } = this.state;

    let indexOfTarget;
    let indexOfDragged;

    displayOrder.forEach((element, index) => {
      if (element.id === draggedHeader.id) {
        indexOfDragged = index;
      }
      if (element.id === targetHeader.id) {
        indexOfTarget = index;
      }
    });

    let newDisplayOrder = this.array_move(
      displayOrder,
      indexOfDragged,
      indexOfTarget
    );

    this.setState(
      { displayOrder: newDisplayOrder },
      this.sortDisplay(newDisplayOrder)
    );
  };

  /* sorts displayed headers by definded order */
  sortDisplay = displayOrder => {
    const { display } = this.state;
    let displayUpdated = [...display];

    displayUpdated.sort((a, b) => {
      return displayOrder.indexOf(a) - displayOrder.indexOf(b);
    });

    this.setState({ display: displayUpdated });
  };

  getStyleOfRow = index => {
    const { indexOfDraggedColumn, indexOfEnteredColumn } = this.state;
    const classnames = classNames('px-6 py-6 hover:py-8', {
      'border-dashed border-l-2 border-indigo-200':
        indexOfEnteredColumn === index && indexOfDraggedColumn >= index,
      'border-dashed border-r-2 border-indigo-200':
        indexOfEnteredColumn === index && indexOfDraggedColumn < index,
      'border-r border-gray-200': indexOfEnteredColumn !== index
    });
    return classnames;
  };

  /* render all data rows */
  mapData = () => {
    const { display, data, currentPage } = this.state;
    const { options, isFetching, globalSelect, pagination } = this.props;

    if (display && display.length > 0) {
      if (data && data.length > 0) {
        return data.map((element, index) => {
          // pagination
          if (
            !pagination ||
            (index >= currentPage * pagination &&
              index < currentPage * pagination + pagination)
          ) {
            return (
              <tr
                key={index}
                className="text-center text-sm text-gray-700 shadow-inner even:bg-gray-100 hover:text-biologis-blue bg-gradient-to-t hover:via-transparent hover:from-gray-300"
              >
                {display.map((key, indexHeader) => {
                  return (
                    <td
                      key={index + key.id}
                      className={this.getStyleOfRow(indexHeader)}
                    >
                      <span>{element[key.id]}</span>
                    </td>
                  );
                })}
                {options && options.length === 1 && (
                  <td className="px-6 py-2 hover:py-8">
                    <Button
                      size="xs"
                      onClick={() => options[0].func(element.id)}
                    >
                      {options[0].label}
                    </Button>
                  </td>
                )}
                {options && options.length > 1 && (
                  <td className="px-6 py-2 hover:py-8">
                    <OperationSelect
                      target={element.id}
                      options={options}
                      globalSelect={globalSelect}
                    />
                  </td>
                )}
              </tr>
            );
          } else {
            return null;
          }
        });
      } else {
        return (
          <tr>
            <td colSpan={display.length} className="text-center">
              {isFetching ? (
                <span className="text-gray-700 px-6 py-3 flex items-center">
                  <LoadingSpinner color="black" />
                  Loading...
                </span>
              ) : (
                <span className="text-gray-700 px-6 py-3 flex items-center">
                  No items!
                </span>
              )}
            </td>
          </tr>
        );
      }
    }
  };

  /* provides content for display dropdown */
  getDisplayOptions = () => {
    const { display, displayOrder } = this.state;

    return displayOrder.map((header, index) => {
      return (
        <label
          key={index}
          className="flex items-center justify-between py-2 px-4 hover:bg-gray-100 hover:text-biologis-blue"
        >
          <span>{header.label}</span>
          <input
            type="checkbox"
            checked={display.some(element => {
              return element.id === header.id;
            })}
            onChange={e => this.handleColumnStateChange(e, header)}
          />
        </label>
      );
    });
  };

  /* search bar handler */
  handleSearchChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });

    if (event.target.id === 'search') {
      return this.searchElement(event.target.value);
    }
  };

  /* activates/deactivates columns on check */
  handleColumnStateChange = (event, header) => {
    const { display, displayOrder } = this.state;

    let displayUpdated = [...display];

    if (event.target.checked) {
      displayUpdated.push(header);
    } else {
      const index = displayUpdated.findIndex(
        element => element.id === header.id
      );
      if (index > -1) {
        displayUpdated.splice(index, 1);
      }
    }

    displayUpdated.sort((a, b) => {
      return displayOrder.indexOf(a) - displayOrder.indexOf(b);
    });

    this.setState({ display: displayUpdated });
  };


  /* filter elements by search */
  searchElement = search => {
    let filteredData = this.getFormattedData().filter(element => {
      return this.objectValuesContainSearchAsSubstring(search, element);
    });
    return this.setState({ data: filteredData });
  };


  /* helper for searching; recursively go through object values looking for search as substring */
  objectValuesContainSearchAsSubstring(text, value) {
    if (typeof value === 'string') {
      return value.toUpperCase().includes(text.toUpperCase());
    } else if (typeof value === 'object') {
      return Object.values(value).some(val =>
        this.objectValuesContainSearchAsSubstring(text, val)
      );
    }
    return false;
  }

  getFilter = () => {
    const { advancedFilter } = this.props;
    const { search } = this.state;
    if (advancedFilter) {
      return advancedFilter;
    } else {
      return (
        <TextInput
          id="search"
          size="md"
          placeholder="Search..."
          onChange={e => this.handleSearchChange(e)}
          value={search}
        />
      );
    }
  };

  changePage = page => {
    const { pagination } = this.props;
    const { data } = this.state;
    if (page >= 0 && page < Math.ceil(data.length / pagination)) {
      this.setState({ currentPage: page });
    }
  };

  getPagination = () => {
    const { isFetching, pagination, headers, options } = this.props;
    const { currentPage, data } = this.state;

    if(!data | !headers) {
      debugger
    }
    return (
      <tr>
        <td colSpan={options ? headers.length + 1 : headers.length}>
          <div className="flex justify-around items-center py-4 bg-gray-100 rounded w-full">
            <div className="w-16">
              <Button
                size="sm"
                color="secondary"
                onClick={() => this.changePage(0)}
                type="button"
              >
                {'<<'}
              </Button>
            </div>
            <div className="w-16">
              <Button
                size="sm"
                color="secondary"
                onClick={() => this.changePage(currentPage - 1)}
                type="button"
              >
                {'<'}
              </Button>
            </div>
            <div className="text-xs text-center w-64">
              {isFetching ? (
                <div className="flex justify-center">
                  <div className="flex justify-center items-center text-xs">
                    <LoadingSpinner color="black" />
                    <span>Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  {'Page ' +
                    (currentPage + 1) +
                    ' of ' +
                    (data && Math.ceil(data.length / pagination))}
                </div>
              )}
            </div>
            <div className="w-16">
              <Button
                size="sm"
                color="secondary"
                onClick={() => this.changePage(currentPage + 1)}
                type="button"
              >
                {'>'}
              </Button>
            </div>
            <div className="w-16">
              <Button
                size="sm"
                color="secondary"
                onClick={() =>
                  this.changePage(Math.ceil(data.length / pagination) - 1)
                }
                type="button"
              >
                {'>>'}
              </Button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { display } = this.state;
    const { pagination } = this.props;

    return (
      <div>
        <div className="flex justify-between">
          <div className="mx-4 my-2 w-64">{this.getFilter()}</div>
          <div className="mx-4 my-2">
            <Dropdown label={'Display ( ' + display?.length + ' )'}>
              {this.getDisplayOptions()}
            </Dropdown>
          </div>
        </div>
        <div className="m-4 bg-white rounded-lg shadow">
          <table className="table-auto bg-white w-full">
            <thead>{this.mapHeaders()}</thead>
            <tbody>{this.mapData()}</tbody>
            {pagination && <tfoot>{this.getPagination()}</tfoot>}
          </table>
        </div>
      </div>
    );
  }
}

DataTable.defaultProps = {};

DataTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.array,
  keys: PropTypes.array,
  isFetching: PropTypes.bool,
  advancedFilter: PropTypes.node,
  globalSelect: PropTypes.string,
  pagination: PropTypes.number
};

export default DataTable;
