import { useState } from 'react';

export default function Toggle({ children }) {
  const [on, setOn] = useState(false);

  const toggle = () => {
    setOn(!on)
  };

    return children({
      on: on,
      toggle: toggle
    });
}
