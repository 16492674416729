import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR
} from '../actions/products';

const initState = {
  data: [],
  isFetching: false,
  error: false
};

const productsReducer = (state = initState, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default productsReducer;
