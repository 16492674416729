import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import {
  fetchGenotypeDataByUUID,
  fetchPhenoByGenotype,
  setGenotypeDataByGenotypeUUID,
  fetchPossibleWfStates,
} from '../../../actions/genotypes';
import DataTable from '../../../components/DataTable';
import TextInput from '../../../components/TextInput';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import InfoboxOverlay from '../../../components/InfoboxOverlay';
import { addAlert, dismissAlert } from '../../../actions/ui';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { StatusGood } from 'grommet-icons';

export class EditGenotype extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genotype: {},
      genotypeName: '',
      assocPhenotype: '',
      wfState: '',
      saved: null,
      isFetchingSingle: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.saveOnEnter, false);
    return this.loadData();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.saveOnEnter, false);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeGenotype.genotype.id !==
      prevProps.activeGenotype.genotype.id
    ) {
      this.loadData();
    }
  }

  saveOnEnter = (event) => {
    const { genotype, genotypeName, assocPhenotype, wfState } = this.state;
    if (event.keyCode === 13) {
      this.handleSave(genotype.id, genotypeName, assocPhenotype, wfState);
    }
  };

  loadData() {
    const { location } = this.props;

    const params = new URLSearchParams(location.search.substring(1));
    const genotypeUuid = params.get('genotypeUuid');

    if (genotypeUuid) {
      this.loadGenotype(genotypeUuid);
      this.loadAssocPhenotypes(genotypeUuid);
      this.loadPossibleWfStates();
    } else {
      console.error(
        'No UUID found: Fetching a single genotype is not possible without UUID!',
      );
    }
  }

  loadGenotype(genotypeUuid) {
    const { fetchGenotypeDataByUUID } = this.props;

    this.setState({ isFetchingSingle: true }, () =>
      fetchGenotypeDataByUUID(genotypeUuid).then((action) => {
        const {
          haplotype_name,
          phenotype_endpoint_uuid,
          l_phenotype_haplotype_wf_status_id,
        } = action.payload.result[0];
        this.setState({
          genotype: action.payload.result[0],
          genotypeName: haplotype_name,
          assocPhenotype: phenotype_endpoint_uuid,
          wfState: l_phenotype_haplotype_wf_status_id,
          isFetchingSingle: false,
        });
      })
    );
  }

  loadAssocPhenotypes(genotypeUuid) {
    const { fetchPhenoByGenotype } = this.props;
    return fetchPhenoByGenotype(genotypeUuid);
  }

  loadPossibleWfStates() {
    const { fetchPossibleWfStates } = this.props;
    return fetchPossibleWfStates();
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });

    if (ev.target.id !== 'wfState') {
      this.setState({ wfState: '1' });
    }
  };

  async handleSave(genotypeUuid, genotypeName, phenotypeUuid, wfState) {
    const { setGenotypeDataByGenotypeUUID } = this.props;
    const {
      haplotype_name,
      phenotype_endpoint_uuid,
      l_phenotype_haplotype_wf_status_id
    } = this.props.activeGenotype.genotype;

    // check for selected uuid
    if (genotypeUuid) {
      // check for empty values
      if (genotypeName === '') {
        genotypeName = haplotype_name;
      }
      if (phenotypeUuid === '') {
        phenotypeUuid = phenotype_endpoint_uuid;
      }
      if (wfState === '') {
        wfState = l_phenotype_haplotype_wf_status_id;
      }

      // check for changes
      if (
        genotypeName !== haplotype_name ||
        phenotypeUuid !== phenotype_endpoint_uuid ||
        wfState !== l_phenotype_haplotype_wf_status_id
      ) {
        try {
          const genotypeUpdated = await setGenotypeDataByGenotypeUUID(
            genotypeUuid,
            genotypeName,
            phenotypeUuid,
            wfState
          );

          this.setState(
            {
              saved: new Date(),
              genotype: genotypeUpdated.payload.result[0]
            },
            () =>
              this.setAlerts({
                id: 'setGenotypeSuccess',
                header: 'Request successful!',
                info: 'The genotype has been changed successfully!',
                type: 'success'
              })
          );
        } catch (e) {
          this.setAlerts({
            id: 'setGenotypeError',
            header: 'Request failed!',
            info:
              'The genotype has not been updated. The server responded with an error!',
            type: 'error'
          });

          console.error(e);
        }
      } else {
        this.setAlerts({
          id: 'setGenotypeError',
          header: 'Request failed!',
          info: 'Nothing has been changed since last save!',
          type: 'error'
        });
      }
    } else {
      this.setAlerts({
        id: 'setGenotypeError',
        header: 'Request failed!',
        info:
          'The genotype has not been updated. Please reselect genotype in your worklist and try again!',
        type: 'error'
      });
    }
  }

  setAlerts(alert) {
    const { addAlert, dismissAlert } = this.props;

    addAlert(alert);

    setTimeout(() => dismissAlert(alert.id), 4000);
  }

  getTableHeaders = () => {
    return [
      { label: 'Gene', id: 'gene_id' },
      { label: 'cDNA change', id: 'variant_cdna' },
      { label: 'rs ID', id: 'variant_version_dbsnp' },
      { label: 'Protein change', id: 'variant_proteinchange' },
      { label: 'Allele name', id: 'variant_allelename' },
      {
        label: 'Last Zygosity',
        id: 'genotype_zygosity'
      }
    ];
  };

  getGenotypeData = () => {
    const { genotype } = this.props.activeGenotype;

    let data = [];

    if (genotype && genotype.genotype_data) {
      data = genotype.genotype_data.map(element => {
        return { gene_id: genotype.associated_gene_id, ...element };
      });
    }

    return data;
  };

  getSavedDate = saved => {
    return (
      'Last save: ' +
      new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(saved)
    );
  };

  render() {
    const {
      assocPhenotypes,
      possibleWfStates,
      isFetching,
      activeGenotype
    } = this.props;
    const {
      genotype,
      genotypeName,
      assocPhenotype,
      wfState,
      saved,
      isFetchingSingle
    } = this.state;

    return (
      <InfoboxOverlay>
        {() => (
          <div className="m-4">
            <div>
              {!isFetching ? (
                <div>
                  <div className="flex px-3 py-2 items-center justify-between">
                    <div>
                      Selected genotype:
                      <h1 className="text-lg font-semibold inline-block ml-4">
                        {genotype.haplotype_name}
                      </h1>
                    </div>

                    {/* select workflow state */}
                    <div className="flex items-center">
                      {/* if user has saved, the time of the action will be displayed in the header */}
                      {saved && (
                        <div className="flex justify-between px-4 py-2 text-biologis-green items-center bg-green-100 rounded-lg mr-4">
                          <StatusGood className="stroke-current" />
                          <span className="px-2">
                            {this.getSavedDate(saved)}
                          </span>
                        </div>
                      )}
                      <div className="mr-4">
                        <label htmlFor="wfState">State:</label>
                      </div>
                      <div>
                        <Select
                          id="wfState"
                          options={possibleWfStates}
                          onChange={this.handleChange}
                          selected={
                            wfState ||
                            genotype.l_phenotype_haplotype_wf_status_id
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr className="my-4 shadow" />

                    {/* variants */}
                    <div className="mx-4">
                      {/* if genotype has the 'wildtype' flag this message will be rendered */}
                      {genotype.haplotype_is_reference ? (
                        <div className="text-center border border-gray-500 rounded overflow-hidden">
                          <div className="pt-6 font-semibold bg-gray-100">
                            Homozygous reference for all tested positions in
                            this gene
                          </div>
                        </div>
                      ) : (
                        // otherwise variant table data
                        <div>
                          <DataTable
                            headers={this.getTableHeaders()}
                            data={this.getGenotypeData()}
                            isFetching={isFetching || isFetchingSingle}
                          />
                        </div>
                      )}
                    </div>
                    <hr className="my-8 shadow" />

                    {/* input labels */}
                    <div className="mx-4">
                      <h1 className="my-4 font-semibold">Edit genotype:</h1>

                      <div className="flex justify-between mx-4">
                        <div className="w-2/5">
                          <label
                            className="block text-sm font-bold mb-2"
                            htmlFor="genotypeName"
                          >
                            Genotype name:
                          </label>
                        </div>

                        <div className="w-2/5">
                          <label
                            className="block text-sm font-bold mb-2"
                            htmlFor="assocPhenotypes"
                          >
                            associated Phenotype:
                          </label>
                        </div>
                        <div className="w-32"></div>
                      </div>

                      {/* input forms */}
                      <div className="flex justify-between mx-4">
                        {/* genotype name by state */}
                        <div className="w-2/5">
                          <TextInput
                            id="genotypeName"
                            value={genotypeName}
                            placeholder={
                              genotypeName || genotype.haplotype_name
                            }
                            onChange={this.handleChange}
                            size="md"
                          />
                        </div>

                        {/* associated phenotypes by props */}
                        <div className="w-2/5">
                          <Select
                            id="assocPhenotype"
                            placeholder="Select a phenotype..."
                            options={assocPhenotypes}
                            onChange={this.handleChange}
                            selected={
                              assocPhenotype || genotype.phenotype_endpoint_uuid
                            }
                            size="md"
                          />
                        </div>

                        {/* Save changes */}
                        <div className="w-32">
                          <Button
                            color="primary"
                            disabled={isFetching}
                            onClick={() => {
                              this.handleSave(
                                activeGenotype.genotype.id,
                                genotypeName,
                                assocPhenotype,
                                wfState
                              );
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                    <hr className="my-8 shadow" />
                  </div>
                </div>
              ) : (
                <div className="flex px-3 py-2 items-center">
                  <LoadingSpinner color="black" />
                  Loading...
                </div>
              )}
            </div>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

EditGenotype.defaultProps = {
  genotypes: {}
};

EditGenotype.propTypes = {
  genotypes: PropTypes.object.isRequired,
  allGenotypes: PropTypes.object.isRequired,
  fetchPhenoByGenotype: PropTypes.func.isRequired,
  fetchPossibleWfStates: PropTypes.func.isRequired,
  fetchGenotypeDataByUUID: PropTypes.func.isRequired,
  setGenotypeDataByGenotypeUUID: PropTypes.func.isRequired,
  assocPhenotypes: PropTypes.array,
  possibleWfStates: PropTypes.array,
  request_error: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  activeGenotype: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    genotypes: state.genotypes.genotypes.byId,
    allGenotypes: state.genotypes.allGenotypes.byId,
    assocPhenotypes: state.genotypes.activeGenotype.assocPhenotypes,
    possibleWfStates: state.genotypes.possibleWfStates,
    error: state.genotypes.error,
    isFetching: state.genotypes.activeGenotype.isFetching,
    activeGenotype: state.genotypes.activeGenotype,
  };
};

export default (
  connect(mapStateToProps, {
    fetchPhenoByGenotype,
    setGenotypeDataByGenotypeUUID,
    fetchPossibleWfStates,
    fetchGenotypeDataByUUID,
    addAlert,
    dismissAlert,
  })(EditGenotype)
);
