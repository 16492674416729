import React, { Component } from 'react';
import PageWithNav from '../../components/PageWithNav';
import { Routes, Route, Link, useResolvedPath } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlShape } from '../../utils/intl';
import Upload from './Upload';
import Patients from './Patients';
import Orders from './Orders';

const SSUP = ({intl}) => {

  const url = useResolvedPath('').pathname;

    return (
      <PageWithNav
        name={intl.formatMessage({ id: 'tools.ssup.name' })}
        nav={({ on }) => (
          <ul>
            <li className="px-4 py-2">
              {on && (
                <Link to={url}>
                  <FormattedMessage id="tools.ssup.options.upload.name" />
                </Link>
              )}
            </li>
            <li className="px-4 py-2">
              {on && (
                <Link to={`${url}/patients`}>
                  <FormattedMessage id="tools.ssup.options.patients.name" />
                </Link>
              )}
            </li>
            <li className="px-4 py-2">
              {on && (
                <Link to={`${url}/orders`}>
                  <FormattedMessage id="tools.ssup.options.orders.name" />
                </Link>
              )}
            </li>
          </ul>
        )}
      >
          {({ router, path, params }) => {
        return <Routes>
          <Route path="/" element={<Upload />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/orders" element={<Orders />} />
        </Routes> }}
      </PageWithNav>
    );
}

export default injectIntl(SSUP);
