import React from 'react';
import { PropTypes } from 'prop-types';
import { FormField } from '../FormField';

export const FormBlock = ({ fieldData, errors }) => (
  <>
    {fieldData.type === 'checkbox' ? (
      <FormField fieldData={fieldData} errors={errors} />
    ) : (
      <div className="p-4 py-2 flex justify-between items-center">
        <label htmlFor={fieldData.name} className="w-1/3 text-left">
          {fieldData.label}
          {fieldData.required && <span className="required">*</span>}
        </label>
        <div className="w-2/3">
          <div>
            <FormField fieldData={fieldData} errors={errors} />
          </div>
        </div>
      </div>
    )}
    {errors[fieldData.name] && (
      <div className="invalid-feedback">{errors[fieldData.name]}</div>
    )}
  </>
);

FormBlock.propTypes = {
  fieldData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
