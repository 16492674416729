import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlShape } from '../../utils/intl';
import { Link, Route, Routes } from 'react-router-dom';
import PageWithNav from '../../components/PageWithNav';
import Account from './Account';

export class Profile extends Component {
  static propTypes = {
    intl: IntlShape.isRequired,
    user: PropTypes.object
  };

  render() {
    const { user, intl } = this.props;

    return (
      <div className="w-full">
        <PageWithNav
          name={intl.formatMessage({ id: 'settings.name' })}
          nav={({ on, router, path, params }) => (
              <ul>
                <li className="px-4 py-2">
                  {on && (
                    <Link to={`${path}`}>
                      <FormattedMessage id="settings.options.account.name" />
                    </Link>
                  )}
                </li>
              </ul>
          )}
        >
          {({ router, path, params }) => {
            return <Routes>
              <Route exact path={"/"} element={<Account user={user} />} />
            </Routes>
          }}
        </PageWithNav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default injectIntl(connect(mapStateToProps)(Profile));
