import { jsonrpc } from '@brightsolutionsgmbh/client-core';

/* Fetches all Genotypes */
export const ALL_GENOTYPES_REQUEST = '@@genotypes/ALL_GENOTYPES_REQUEST';
export const ALL_GENOTYPES_SUCCESS = '@@genotypes/ALL_GENOTYPES_SUCCESS';
export const ALL_GENOTYPES_ERROR = '@@genotypes/ALL_GENOTYPES_ERROR';

export const fetchAllGenotypesReduced = () => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.fetchHaplotypesReduced',
    params: {
      langcode: 'de'
    },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [ALL_GENOTYPES_REQUEST, ALL_GENOTYPES_SUCCESS, ALL_GENOTYPES_ERROR],
    body
  );
};

/* Fetches all genotypes which haven't been published yet */
export const GENOTYPES_WORKLIST_REQUEST =
  '@@genotypes/GENOTYPES_WORKLIST_REQUEST';
export const GENOTYPES_WORKLIST_SUCCESS =
  '@@genotypes/GENOTYPES_WORKLIST_SUCCESS';
export const GENOTYPES_WORKLIST_ERROR = '@@genotypes/GENOTYPES_WORKLIST_ERROR';

export const fetchGenotypesWorklist = () => {
  let body = {
    jsonrpc: '2.0',
    method:
      'as_kmm_communication.fetchHaplotypeDataByHaplotypePhenotypeWorkflowState',
    params: {
      langcode: 'de'
    },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [
      GENOTYPES_WORKLIST_REQUEST,
      GENOTYPES_WORKLIST_SUCCESS,
      GENOTYPES_WORKLIST_ERROR
    ],
    body
  );
};

/* Fetches a single genotype based on its uuid */
export const GENOTYPE_REQUEST = '@@genotypes/GENOTYPE_REQUEST';
export const GENOTYPE_SUCCESS = '@@genotypes/GENOTYPE_SUCCESS';
export const GENOTYPE_ERROR = '@@genotypes/GENOTYPE_ERROR';

export const fetchGenotypeDataByUUID = uuid => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.fetchHaplotypeDataByHaplotypeUuid',
    params: {
      langcode: 'de',
      haplotype_uuid: uuid
    },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [GENOTYPE_REQUEST, GENOTYPE_SUCCESS, GENOTYPE_ERROR],
    body
  );
};

export const UPDATEGENOTYPE_REQUEST = '@@genotypes/UPDATEGENOTYPE_REQUEST';
export const UPDATEGENOTYPE_SUCCESS = '@@genotypes/UPDATEGENOTYPE_SUCCESS';
export const UPDATEGENOTYPE_ERROR = '@@genotypes/UPDATEGENOTYPE_ERROR';

export const setGenotypeDataByGenotypeUUID = (
  haplotype_uuid,
  haplotype_desc = undefined,
  phenotype_uuid = undefined,
  workflow_state = undefined
) => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.setHaplotypeDataByHaplotypeUuid',
    params: {
      haplotype_uuid: haplotype_uuid,
      phenotype_uuid: phenotype_uuid,
      workflow_state: workflow_state,
      haplotype_desc: haplotype_desc,
      langcode: 'de'
    },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [UPDATEGENOTYPE_REQUEST, UPDATEGENOTYPE_SUCCESS, UPDATEGENOTYPE_ERROR],
    body
  );
};

/* Fetches all possible phenotypes for a single genotype */
export const ASSOCPHENOTYPES_REQUEST = '@@genotypes/ASSOCPHENOTYPES_REQUEST';
export const ASSOCPHENOTYPES_SUCCESS = '@@genotypes/ASSOCPHENOTYPES_SUCCESS';
export const ASSOCPHENOTYPES_ERROR = '@@genotypes/ASSOCPHENOTYPES_ERROR';

export const fetchPhenoByGenotype = uuid => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.fetchPhenoByHaplotype',
    params: {
      langcode: 'de',
      haplotype_uuid: uuid
    },
    id: 'kmm_backend'
  };

  return jsonrpc.request(
    'POST',
    '',
    [ASSOCPHENOTYPES_REQUEST, ASSOCPHENOTYPES_SUCCESS, ASSOCPHENOTYPES_ERROR],
    body
  );
};

/* Fetches all possible workflow states in database */
export const POSSIBLE_WFSTATES_REQUEST =
  '@@genotypes/POSSIBLE_WFSTATES_REQUEST';
export const POSSIBLE_WFSTATES_SUCCESS =
  '@@genotypes/POSSIBLE_WFSTATES_SUCCESS';
export const POSSIBLE_WFSTATES_ERROR = '@@genotypes/POSSIBLE_WFSTATES_ERROR';

export const fetchPossibleWfStates = () => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.fetchPossibleAsMetadataWfStates',
    params: { langcode: 'de' },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [
      POSSIBLE_WFSTATES_REQUEST,
      POSSIBLE_WFSTATES_SUCCESS,
      POSSIBLE_WFSTATES_ERROR
    ],
    body
  );
};

/* Fetches all active ingredients text data */
export const ACTIVE_INGREDIENTS_REQUEST =
  '@@genotypes/ACTIVE_INGREDIENTS_REQUEST';
export const ACTIVE_INGREDIENTS_SUCCESS =
  '@@genotypes/ACTIVE_INGREDIENTS_SUCCESS';
export const ACTIVE_INGREDIENTS_ERROR = '@@genotypes/ACTIVE_INGREDIENTS_ERROR';

export const fetchActiveIngredientDataTextByGeneId = () => {
  let body = {
    jsonrpc: '2.0',
    method: 'as_kmm_communication.fetchActiveIngredientsByPhenotypeUuid',
    params: {
      phenotype_uuid: '089421c9-7848-40f0-a11b-41a2815a77d8',
      langcode: 'de'
    },
    id: 'as_kmm_communication'
  };

  return jsonrpc.request(
    'POST',
    '',
    [
      ACTIVE_INGREDIENTS_REQUEST,
      ACTIVE_INGREDIENTS_SUCCESS,
      ACTIVE_INGREDIENTS_ERROR
    ],
    body
  );
};
