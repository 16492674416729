import React from 'react';
import { PropTypes } from 'prop-types';

export const Checkbox = ({ field, id, label }) => {
  return (
    <form>
      <label htmlFor={id}>
        <input
          name={field.name}
          id={id}
          type="checkbox"
          value={field.value}
          checked={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
        {label}
      </label>
    </form>
  );
};

Checkbox.propTypes = {
  field: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
