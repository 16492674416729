import React from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import classNames from 'classnames';

const TextInput = ({
  placeholder,
  value,
  size,
  confirm,
  error,
  className,
  type,
  ...others
}) => {
  const classes = classNames(
    'w-full text-gray-700 bg-gray-200 leading-tight appearance-none rounded py-2 px-3 shadow focus:outline-none focus:shadow-outline',
    {
      'h-6 text-xs': size === 'xs',
      'h-8 text-sm': size === 'sm', //default
      'h-10 text-base': size === 'md'
    },
    {
      'border border-biologis-green': confirm,
      'border border-biologis-red': error
    },
    className
  );

  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      className={classes}
      {...others}
    />
  );
};

TextInput.defaultProps = {
  size: 'sm',
  placeholder: '',
  value: '',
  type: 'text'
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  confirm: PropTypes.bool,
  error: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string
};

export default TextInput;
