import React from 'react';
import { Formik } from 'formik';
import Button from '../Button';
import { PropTypes } from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import { FormBlock } from './FormBlock';

const SchemaForm = ({
  formFields,
  validate,
  submit,
  reset,
  submitButtonColor,
  submitButtonLabel,
  resetButtonLabel,
  renderButtons,
  ...rest
}) => {
  const initialValues = {};
  let render;

  if (Array.isArray(formFields)) {
    formFields.forEach(field => {
      initialValues[field.name] = field.value || '';
    });

    render = errors =>
      formFields.map((formField, index) => (
        <FormBlock fieldData={formField} errors={errors} key={index} />
      ));
  } else if (isPlainObject(formFields)) {
    Object.keys(formFields).forEach(i => {
      // eslint-disable-next-line
      Object.keys(formFields[i]).map(j => {
        if (Array.isArray(formFields[i][j])) {
          formFields[i][j].forEach(field => {
            initialValues[field.name] = field.value || '';
          });
        }
      });
    });

    render = errors =>
      Object.keys(formFields).map(i => (
        <div key={i}>
          <h3 className="px-4 pb-4 text-left text-base font-semibold w-1/3">
            {formFields[i].section_label}
          </h3>
          {// eslint-disable-next-line
            Object.keys(formFields[i]).map(j => {
            if (Array.isArray(formFields[i][j])) {
              return formFields[i][j].map((formField, index) => (
                <FormBlock fieldData={formField} errors={errors} key={index} />
              ));
            }
          })}
          <hr className="m-4" />
        </div>
      ));
  } else {
    throw TypeError('Type not supported!');
  }

  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => submit(values, actions)}
        onReset={(values, actions) => reset(values, actions)}
        validate={validate}
        render={({
          handleSubmit,
          handleReset,
          isSubmitting,
          errors,
          values
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            {render(errors)}
            {renderButtons ? (
              renderButtons({
                handleSubmit,
                handleReset,
                values,
                isSubmitting,
                errors,
                formFields
              })
            ) : (
              <>
                <div className="flex justify-end mr-4">
                  <div className="w-64">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      color={submitButtonColor}
                    >
                      {submitButtonLabel}
                    </Button>
                    <div className="ml-4">
                      {reset && (
                        <Button onClick={handleReset}>
                          {resetButtonLabel}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        )}
      />
    );
  }
};

SchemaForm.propTypes = {
  submit: PropTypes.func,
  formFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reset: PropTypes.func,
  submitButtonColor: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  resetButtonLabel: PropTypes.string,
  validate: PropTypes.func,
  renderButtons: PropTypes.func
};

SchemaForm.defaultProps = {
  formFields: [],
  submitButtonColor: 'primary',
  submitButtonLabel: 'Submit',
  resetButtonLabel: 'Reset'
};

export default SchemaForm;
