import React, { Component } from 'react';
import PageWithNav from '../../components/PageWithNav';
import { Routes, Route, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlShape } from '../../utils/intl';
import SearchGenotypeTable from './SearchGenotypeTable';
import EditGenotype from './EditGenotype';

export class VariantManager extends Component {
  static propTypes = {
    intl: IntlShape.isRequired,
  };

  render() {
    const { intl } = this.props;

    return (
      <PageWithNav
        name={intl.formatMessage({ id: 'tools.variantManager.name' })}
        nav={({ on, router, path, params }) => (
          <ul>
            <li className="px-4 py-2">
              {on && (
                <Link to={`${path}`}>
                  <FormattedMessage id="tools.variantManager.options.search.name" />
                </Link>
              )}
            </li>
          </ul>
        )}
      >
        {({ router, path, params }) => {
          return <Routes>
            <Route path={`/`} element={<SearchGenotypeTable />} />
            <Route path={`${path}/edit-genotype`} element={<EditGenotype />} />
          </Routes>
        }}
      </PageWithNav>
    );
  }
}
export default injectIntl(VariantManager);
