import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import { getPatientList } from '../../../actions/patients';
import { createReportOrder } from '../../../actions/report';
import { getProducts } from '../../../actions/products';
import { apiService } from '../../../services';
import DataTable from '../../../components/DataTable';
import DateRangePicker from '../../../components/DateRangePicker';
import TextInput from '../../../components/TextInput';
import OperationSelect from '../../../components/OperationSelect';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import LoadingSpinner from '../../../components/LoadingSpinner';
import InfoboxOverlay from '../../../components/InfoboxOverlay';
import { addAlert, dismissAlert } from '../../../actions/ui';

export class Patients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: { patientId: '', startDate: '', endDate: '' },
      submittedFilter: {},
      currentPage: 0,
      processedIds: [],
      tableData: [],
      languageForAll: '',
      patientsToProceedCount: 0,
    };
  }

  componentDidMount() {
    this.getRequiredData();
    this.loadData({}, 0);
    this.setTableData();
  }

  componentDidUpdate(prevProps) {
    const { patients } = this.props;

    if (JSON.stringify(prevProps.patients) !== JSON.stringify(patients)) {
      this.setTableData();
    }
  }

  resetProcessedIdsState = () => {
    this.setState({ processedIds: [] });
  };

  loadData = (filter, page) => {
    this.props.getPatientList(pickBy({ page: page, ...filter }, identity));
    this.setTableData();
  };

  setTableData = () => {
    const { patients } = this.props;
    const tableData = patients.map((item) => {
      item.lang = '';

      return item;
    });
    this.setState({ tableData });
  };

  getRequiredData = async () => {
    try {
      const { customerRelation, getCustomer, getProducts } = this.props;
      await getCustomer(`/as_customer/as_customer/${customerRelation.id}`);
      await getProducts();
      return true;
    } catch (e) {
      return e;
    }
  };

  getPatientsToProcess = (patients) => {
    return patients.filter((patient) => patient.lang !== '');
  };

  submitForm = e => {
    const { products, createReportOrder } = this.props;
    const { tableData } = this.state;

    const patientsToProcess = this.getPatientsToProcess(tableData);
    this.resetProcessedIdsState();

    if (products.length > 0 && patientsToProcess.length > 0) {
      patientsToProcess.forEach(patient => {
        const body = {
          patient_id: patient.id,
          order_id: patient.id,
          langcode: patient.lang,
          clinical_report: products[0].attributes.main_order.uuid,
          // Filename prefix
          external_reference_id: patient.id + '_' + dayjs().format('YYYY-MM-DD')
        };

        createReportOrder(body).then(action => {
          this.setState({
            processedIds: [...this.state.processedIds, action.payload.order_id]
          });
          if (patientsToProcess.length < 10) {
            this.setAlert({
              id: 'proceedCreateReportOrdersSuccess' + patient.id,
              header: 'Report order created!',
              info:
                'The report order with id: ' +
                patient.id +
                ' (' +
                patient.lang.toUpperCase() +
                ') ' +
                ' has been created!',
              type: 'success'
            });
          }
        });
        if (patientsToProcess.length >= 10) {
          this.setAlert({
            id: 'proceedCreateReportOrdersSuccess',
            header: 'Multiple report orders created!',
            info: 'All selected report orders have been created!',
            type: 'success'
          });
        }
      });
    } else {
      this.setAlert({
        id: 'proceedCreateReportOrdersError',
        header: 'Request failed!',
        info: 'No report orders are selected!',
        type: 'error'
      });
    }
    e.preventDefault();
  };

  setAlert = alert => {
    const { addAlert, dismissAlert } = this.props;

    addAlert(alert);

    setTimeout(() => dismissAlert(alert.id), 4000);
  };

  setLanguage = (option, targetId) => {
    const { tableData } = this.state;

    let newTableData = [];

    if (!isNil(targetId)) {
      newTableData = tableData.map(element => {
        const { id } = element;

        if (id === targetId) {
          element.lang = option;
        }
        return element;
      });
    } else {
      // eslint-disable-next-line
      newTableData = tableData.map(element => {
        element.lang = option;
        return element;
      });
    }

    this.setState({ tableData: newTableData });
  };

  handleFilterChange = ev => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, [ev.target.id]: ev.target.value }
    });
  };

  handleClickEnterOnFilter = target => {
    if (target.charCode === 13) {
      this.handleFilterSubmit();
    }
  };

  handleFilterSubmit = () => {
    const { patientId, startDate, endDate } = this.state.filter;
    const filter = {
      patientId: patientId,
      startDate: startDate && dayjs(startDate).unix(),
      endDate:
        endDate &&
        dayjs(endDate)
          .add(86399, 'second')
          .unix()
    };
    this.setState({ submittedFilter: filter }, () => {
      this.loadData(filter, 0);
    });
  };

  getFilter = changePage => {
    const { filter } = this.state;
    return (
      <div
        className="flex justify-between"
        onKeyPress={e => {
          this.handleClickEnterOnFilter(e);
        }}
      >
        <div className="flex">
          <div className="w-64">
            <TextInput
              id="patientId"
              value={filter.patientId}
              onChange={this.handleFilterChange}
              placeholder="Search patient id..."
              size="md"
            />
          </div>
          <div className="ml-4">
            <DateRangePicker
              size="md"
              start={filter.startDate}
              end={filter.endDate}
              onChange={this.handleFilterChange}
            />
          </div>
          <div className="w-16 ml-4">
            <Button
              size="md"
              type="button"
              onClick={() => {
                this.handleFilterSubmit();
                changePage(0);
              }}
            >
              <FormattedMessage id="tools.ssup.options.filter.name" />
            </Button>
          </div>
          <div className="w-16 ml-4">
            <Button
              size="md"
              type="button"
              onClick={() => {
                this.setState({
                  filter: { patientId: '', startDate: '', endDate: '' },
                  submittedFilter: {}
                });
                this.loadData({});
                changePage(0);
              }}
            >
              <FormattedMessage id="tools.ssup.options.reset.name" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  getTableHeaders = () => {
    return [
      { id: 'id', label: 'Patient id' },
      { id: 'created_formatted', label: 'Request date' }
    ];
  };

  getLangOptions = (global = false) => {
    const langOptions = [
      {
        id: 'de',
        value: 'de',
        label: 'German',
        func: id => {
          global &&
            this.setState({
              languageForAll: 'de'
            });
          this.setLanguage('de', id);
        }
      },
      {
        id: 'en',
        value: 'en',
        label: 'English',
        func: id => {
          global &&
            this.setState({
              languageForAll: 'en'
            });
          this.setLanguage('en', id);
        }
      },
      {
        id: 'default',
        value: '',
        func: id => {
          global &&
            this.setState({
              languageForAll: ''
            });
          this.setLanguage('', id);
        }
      }
    ];
    return langOptions;
  };

  getPatientsToProceedCount = () => {
    const { tableData } = this.state;

    return tableData.filter(element => element.lang !== '').length;
  };

  render() {
    const { tableData, submittedFilter, languageForAll } = this.state;
    const {
      pager,
      patientsListIsFetching,
      createReportOrderIsFetching
    } = this.props;

    return (
      <InfoboxOverlay>
        {() => (
          <div className="m-6">
            <Pagination
              pager={pager}
              loadData={this.loadData}
              filter={submittedFilter}
              loading={patientsListIsFetching}
            >
              {({ changePage }) => (
                <div>
                  <form id="generateReports" onSubmit={this.submitForm}>
                    <div className="flex justify-between mx-4 items-center">
                      <h1 className="text-2xl font-bold">Patient list</h1>
                      <div className="flex items-center">
                        <label className="text-xl mr-4">Select all:</label>
                        <div className="w-64">
                          <OperationSelect
                            value={languageForAll}
                            options={this.getLangOptions(true)}
                            size="md"
                          />
                        </div>
                        <div className="w-16">
                          <Button
                            color="primary"
                            type="button"
                            size="md"
                            onClick={() => {
                              this.setLanguage('');
                              this.setState({ languageForAll: '' });
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>

                      <div className="w-64">
                        <Button
                          color="primary"
                          type="submit"
                          size="md"
                          form="generateReports"
                        >
                          {createReportOrderIsFetching ? (
                            <div className="flex">
                              <LoadingSpinner />
                              <span>Proceeding...</span>
                            </div>
                          ) : (
                            <div>
                              <FormattedMessage id="tools.ssup.options.proceed.name" />
                              <span>
                                {' ( ' +
                                  this.getPatientsToProceedCount() +
                                  ' )'}
                              </span>
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                    <hr className="shadow mx-4 mt-4 mb-2" />
                    <DataTable
                      headers={this.getTableHeaders()}
                      data={tableData}
                      advancedFilter={this.getFilter(changePage)}
                      options={this.getLangOptions()}
                      globalSelect={languageForAll}
                    />
                  </form>
                </div>
              )}
            </Pagination>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

Patients.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getPatientList: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  createReportOrder: PropTypes.func.isRequired,
  customerRelation: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  products: PropTypes.array,
  patients: PropTypes.array,
  pager: PropTypes.object,
  createReportOrderIsFetching: PropTypes.bool,
  patientsListIsFetching: PropTypes.bool.isRequired,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    patients: state.patients.data,
    patientsListIsFetching: state.patients.isFetching,
    pager: state.patients.pager,
    customerRelation:
      state.user.data.relationships &&
      state.user.data.relationships.field_customer.data,
    customer: state.customer.data,
    products: state.products.data,
    createReportOrderIsFetching: state.report.isFetching
  };
};


export default injectIntl(
  (
    connect(mapStateToProps, {
      getPatientList,
      getCustomer: apiService.getCustomer,
      getProducts,
      createReportOrder,
      addAlert,
      dismissAlert
    })(Patients)
  )
);
