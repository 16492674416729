import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import LoadingSpinner from '../LoadingSpinner';

const Button = ({
  type = 'button',
  size = 'md',
  color = 'primary',
  outline = false,
  isLoading = false,
  disabled = false,
  children,
  className,
  ...others
}) => {
  const classes = classNames(
    'flex flex-row font-biologis-etelka rounded w-full focus:outline-none items-center px-2',
    {
      'py-1 text-xs': size === 'xs',
      'py-1 text-sm': size === 'sm',
      'py-2 text-base': size === 'md', //default
      'py-3 text-lg': size === 'lg',
      'py-4 text-xl': size === 'xl'
    },
    {
      'bg-biologis-blue text-white hover:bg-blue-600':
        color === 'primary' && !outline && !disabled, // default
      'bg-gray-600 text-white hover:bg-gray-500':
        color === 'secondary' && !outline && !disabled,
      'bg-transparent text-biologis-blue border border-biologis-blue hover:bg-blue-600 hover:text-white':
        color === 'primary' && outline && !disabled,
      'bg-transparent text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white':
        color === 'secondary' && outline && !disabled,
      'text-white bg-gray-400 hover:bg-gray-400 cursor-not-allowed': disabled
    },
    className
  );

  return (
    <button type={type} className={classes} disabled={disabled} {...others}>
      <div className="flex-1">{children}</div>
      {isLoading && <LoadingSpinner />}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default Button;
