import { TOGGLE_SIDEBARNAV, ADD_ALERT, DISMISS_ALERT } from '../actions/ui';

const initState = {
  showSidebar: true,
  alerts: []
};

function addAlert(alerts, newAlert) {
  let updatedAlerts = [...alerts];

  updatedAlerts = updatedAlerts.filter(element => element.id !== newAlert.id);

  updatedAlerts.unshift(newAlert);

  return updatedAlerts;
}

function dismissAlert(alerts, idToDismiss) {
  let updatedAlerts = [...alerts];
  let index = updatedAlerts.findIndex(object => object.id === idToDismiss);

  if (updatedAlerts.length > 0) {
    updatedAlerts.splice(index, 1);
  }
  return updatedAlerts;
}

const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBARNAV:
      return {
        ...state,
        showSidebar: !state.showSidebar
      };
    case ADD_ALERT:
      return {
        ...state,
        alerts: addAlert(state.alerts, action.alert)
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alerts: dismissAlert(state.alerts, action.idToDismiss)
      };
    default:
      return state;
  }
};

export default uiReducer;
