import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as CryptoJS from 'crypto-js';
import { CloudUpload } from 'grommet-icons';
import { injectIntl, FormattedMessage } from 'react-intl';

export class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorId: null,
      currentMimeType: null,
      dragging: false
    };
  }

  fileRef = React.createRef();

  dropRef = React.createRef();

  dragCounter = 0;

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);

    window.addEventListener(
      'dragover',
      function(e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      function(e) {
        e.preventDefault();
      },
      false
    );
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleChange = e => {
    e.stopPropagation();
    e.preventDefault();
    this.handleFile(e.target.files);
    e.target.value = null;
  };

  handleSubmit = file => {
    const status = {
      LOADING: 0,
      COMPLETED: 1,
      ERROR: 2
    };
    const {
      upload,
      onFileUploadStarted,
      onFileUploadedCompleted,
      setAlert
    } = this.props;

    onFileUploadStarted(file);

    upload(file).then(result => {
      if (!result || result.error) {
        file.status = status.ERROR;
        setAlert({
          id: 'ssupUploadError',
          header: 'Upload failed!',
          info: 'Check file and try again or contact support!',
          type: 'error'
        });
      } else {
        file.status = status.COMPLETED;
        setAlert({
          id: 'ssupUploadSuccess',
          header: 'Upload successful!',
          info: 'Your file has been uploaded',
          type: 'success'
        });
      }

      onFileUploadedCompleted(file);
    });
  };

  handleFile = files => {
    const status = {
      LOADING: 0,
      COMPLETED: 1,
      ERROR: 2
    };
    const {
      allowedMimeTypes,
      allowedMimeTypesWithExtension,
      setAlert
    } = this.props;

    for (let i = 0, f; (f = files[i]); i++) {
      const reader = new FileReader();
      const fileToUpload = {};
      fileToUpload.name = f.name;
      this.setState({ currentMimeType: f.type });
      const fileExtension = f.name.split('.').pop();

      if (
        allowedMimeTypes.includes(f.type) ||
        (Object.keys(allowedMimeTypesWithExtension).includes(f.type) &&
          allowedMimeTypesWithExtension[f.type].includes(fileExtension))
      ) {
        reader.onload = theFile => {
          const result = theFile.target.result;
          const fileWordArr = CryptoJS.lib.WordArray.create(result);
          const hash = CryptoJS.SHA1(fileWordArr);

          fileToUpload.hash = hash.toString();
          fileToUpload.body = result;
          fileToUpload.status = status.LOADING;

          this.handleSubmit(fileToUpload);
        };

        reader.readAsArrayBuffer(f);
      } else {
        setAlert({
          id: 'ssupUploadError',
          header: 'Upload failed!',
          info: 'File type is not supported.',
          type: 'error'
        });
      }
    }
  };

  browse = () => {
    const fileInput = this.fileRef.current;
    fileInput.click();
  };

  onDismissError = () => {
    this.setState({ errorId: null });
  };

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };
  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };
  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.handleFile(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  render() {
    const { dragging } = this.state;

    return (
      <div>
        {dragging ? (
          <div
            ref={this.dropRef}
            className="border-2 border-dashed border-gray-300 items-center text-center text-gray-800 py-4 px-8 rounded bg-gradient-to-t from-gray-300"
          >
            <input
              type="file"
              id="file"
              onChange={this.handleChange}
              ref={this.fileRef}
              className="hidden"
            />
            <CloudUpload className="mr-4" />
            <FormattedMessage id="tools.ssup.options.upload.props.uploadForm.drop.name" />
          </div>
        ) : (
          <div
            ref={this.dropRef}
            className="border-2 border-dashed border-gray-300 items-center text-center text-gray-800 py-4 px-8 rounded"
          >
            <input
              type="file"
              id="file"
              onChange={this.handleChange}
              ref={this.fileRef}
              className="hidden"
            />
            <CloudUpload className="mr-4" />
            <FormattedMessage id="tools.ssup.options.upload.props.uploadForm.drag.name" />
            <span
              className="text-biologis-blue hover:text-blue-500 cursor-pointer"
              onClick={this.browse}
            >
              <FormattedMessage id="tools.ssup.options.upload.props.uploadForm.browse.name" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

UploadForm.propTypes = {
  upload: PropTypes.func.isRequired,
  allowedMimeTypes: PropTypes.array,
  allowedMimeTypesWithExtension: PropTypes.object,
  onFileUploadStarted: PropTypes.func,
  onFileUploadedCompleted: PropTypes.func,
  setAlert: PropTypes.func.isRequired
};

UploadForm.defaultProps = {
  onFileUploadStarted: () => {},
  onFileUploadedCompleted: () => {},
  allowedMimeTypes: [
    'text/csv',
    'text/x-csv',
    'text/directory',
    'text/vcard',
    'text/plain',
    'text/x-vcard'
  ],
  // Do not allow xls files, but chrome on windows with excel installed
  // recognizes csv as excel.
  allowedMimeTypesWithExtension: {
    'application/vnd.ms-excel': ['csv']
  }
};

export default injectIntl(UploadForm);
