import { rest, jsonapi } from '@brightsolutionsgmbh/client-core';

export const GET_CONTENT_VARIABLES_FOR_ORDER_REQUEST =
  '@@report/GET_CONTENT_VARIABLES_FOR_ORDER_REQUEST';
export const GET_CONTENT_VARIABLES_FOR_ORDER_SUCCESS =
  '@@report/GET_CONTENT_VARIABLES_FOR_ORDER_SUCCESS';
export const GET_CONTENT_VARIABLES_FOR_ORDER_ERROR =
  '@@report/GET_CONTENT_VARIABLES_FOR_ORDER_ERROR';

export const REPORT_ORDER_REQUEST = '@@report/REPORT_ORDER_REQUEST';
export const REPORT_ORDER_SUCCESS = '@@report/REPORT_ORDER_SUCCESS';
export const REPORT_ORDER_ERROR = '@@report/REPORT_ORDER_ERROR';

export const SET_QUESTIONS_REQUEST = '@@report/SET_QUESTIONS_REQUEST';
export const SET_QUESTIONS_SUCCESS = '@@report/SET_QUESTIONS_SUCCESS';
export const SET_QUESTIONS_ERROR = '@@report/SET_QUESTIONS_ERROR';

export const getReportOrder = (id, field) => {
  return jsonapi.request(
    'GET',
    `/as_report_order/as_report_order/${encodeURIComponent(
      id
    )}?fields[as_report_order--as_report_order]=${field}`,
    [
      GET_CONTENT_VARIABLES_FOR_ORDER_REQUEST,
      GET_CONTENT_VARIABLES_FOR_ORDER_SUCCESS,
      GET_CONTENT_VARIABLES_FOR_ORDER_ERROR
    ]
  );
};

export const setQuestions = (body, id, finish = false) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  return rest.request(
    'patch',
    `/report_order/questionnaire/${encodeURIComponent(id)}?=${
      finish ? 'process=1' : ''
    }`,
    [SET_QUESTIONS_REQUEST, SET_QUESTIONS_SUCCESS, SET_QUESTIONS_ERROR],
    body,
    headers
  );
};

export const createReportOrder = body => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  return rest.request(
    'POST',
    `/as_gims_encrypt/report_order`,
    [REPORT_ORDER_REQUEST, REPORT_ORDER_SUCCESS, REPORT_ORDER_ERROR],
    body,
    headers
  );
};
