import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormDown } from 'grommet-icons';
import { NavLink } from 'react-router-dom';
import Toggle from '../Toggle';
import Dialog from '../Dialog';
import styled from '@emotion/styled';

const StyledFormDown = styled(FormDown)`
  &:hover {
    svg {
      stroke: #6c5dd3;
    }
    color: #6c5dd3;
    text-decoration: none;
  }
  margin-left: 5px;
`;

const NavItem = ({
  path = '/',
  external = false,
  label,
  children,
  className,
  ...rest
}) => {
  const classnames = {
    navItem:
      'flex flex-row items-center inline-block text-sm px-3 py-1 h-8 leading-none rounded hover:text-blue-800 hover:bg-white',
    navItemActive: 'bg-white text-blue-800',
    navItemIcon: 'stroke-current h-4 w-4 ml-1',
    dropdownMenu:
      'absolute flex flex-col z-40 mt-2 bg-white text-black rounded overflow-hidden border border-gray-300 shadow-md mx-2 py-2 w-1/6',
    dropdownItem: 'text-sm block z-40 px-4 py-2 hover:bg-gray-300'
  };

  const [isHovered, setIsHovered] = useState(false)

  if (children) {
    return (
      <Toggle>
        {({ on, toggle }) => {
          const toggleClassnames = classNames(
            classnames.navItem,
            className,
            'mx-2',
            {
              [classnames.navItemActive]: on
            }
          );

          return (
            <div className="block">
              <button onClick={toggle} className={toggleClassnames} {...rest}
               onMouseEnter={() => {setIsHovered(true)}}
               onMouseLeave={() => {setIsHovered(false)}}>
                {label}
                {/* <StyledFormDown color={isHovered ? "#2c5282" : 'white'} /> */}
                <FormDown className={classnames.navItemIcon} />
              </button>
              {on && (
                <Dialog toggle={toggle} className={classnames.dropdownMenu}>
                  {React.Children.map(children, child => {
                    const { className: childClassname } = child.props;
                    const itemClassnames = classNames(
                      classnames.dropdownItem,
                      childClassname
                    );
                    return React.cloneElement(child, {
                      className: itemClassnames
                    });
                  })}
                </Dialog>
              )}
            </div>
          );
        }}
      </Toggle>
    );
  } else {
    const itemClassnames = classNames(classnames.navItem, className);

    if (!external) {
      return (
        <NavLink to={path} className={itemClassnames} {...rest}>
          {label}
        </NavLink>
      );
    } else {
      return (
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          className={itemClassnames}
          {...rest}
        >
          {label}
        </a>
      );
    }
  }
};

NavItem.propTypes = {
  label: PropTypes.node.isRequired,
  path: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default NavItem;
