import { combineReducers } from 'redux';
import {
  authReducer as auth,
  customerReducer as customer,
  actionStackReducer as actionStack,
  userReducer as user,
  fileReducer as file,
  uploadReducer as upload,
  CLEAR_STATE
} from '@brightsolutionsgmbh/client-core';
import uiReducer from './ui';
import genotypes from './genotypes';
import orders from './orders';
import report from './report';
import patients from './patients';
import products from './products';

const appReducer = combineReducers({
  auth,
  actionStack,
  user,
  file,
  ui: uiReducer,
  genotypes,
  report,
  orders,
  patients,
  products,
  customer,
  upload
});

// wrapp combined reducers, this allows us to manipulate
// the global state when specific actions get fired
const rootReducer = (state, action) => {
  // catch logout action and clear global state
  if (action.type === CLEAR_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
