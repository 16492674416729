import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_ERROR
} from '../actions/orders';

const initState = {
  data: {},
  isFetching: false,
  error: false
};

const ordersReducer = (state = initState, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true
      };
    case ORDER_LIST_SUCCESS:
      if (action.payload.data) {
        action.payload.data = action.payload.data.map(data => {
          if (data.relationships && data.relationships.target) {
            const targetId = data.relationships.target.data.id;
            // eslint-disable-next-line
              for (let included of action.payload.included) {
              if (included.id === targetId) {
                data.attributes.clinical_report = included.attributes.title;
                break;
              }
            }
          }
          return data;
        });
      }
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case ORDER_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default ordersReducer;
