/**
 * Safe deep property access for objects.
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/tree/v6.10.0#_get
 * Same as lodash's _.get: https://lodash.com/docs/4.17.15#get
 *
 * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
 *
 * @argument object (Object): The object to query.
 * @argument path (Array|string): The path of the property to get.
 * @argument [defaultValue] (*): The value returned for undefined resolved values.
 **/
export const get = (object, path, defaultValue) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        object,
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === object ? defaultValue : result;
};
