import React, { Component } from 'react';
import UploadForm from './UploadForm';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import DataTable from '../../../components/DataTable';
import uniqBy from 'lodash/uniqBy';
import { apiService } from '../../../services';
import { injectIntl } from 'react-intl';
import InfoboxOverlay from '../../../components/InfoboxOverlay';
import { addAlert, dismissAlert } from '../../../actions/ui';

export class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      patients: []
    };
  }

  onFileUploadStarted = file => {
    this.setState({ fileList: [...this.state.fileList, file] });
  };

  onFileUploadCompleted = () => {
    let { fileList, patients } = this.state;
    const { patientIds } = this.props;
    fileList = [...fileList];
    patients = [...patients, ...patientIds];

    this.setState({ fileList, patients });
  };

  addStatusMessage = array => {
    return array.map(item => {
      let status;
      switch (item.status) {
        case 0:
          status = 'Loading...';
          break;
        case 1:
          status = 'Upload completed';
          break;
        case 2:
          status = 'Upload error!';
          break;
        default:
          status = '';
          break;
      }

      return { ...item, statusLabel: status };
    });
  };

  getHeaders = () => {
    return [
      { label: 'File', id: 'name' },
      { label: 'Status', id: 'statusLabel' }
    ];
  };

  setAlert = alert => {
    const { addAlert, dismissAlert } = this.props;

    addAlert(alert);

    setTimeout(() => dismissAlert(alert.id), 4000);
  };

  render() {
    const { uploadFile } = this.props;
    const { fileList, patients } = this.state;
    const patientIds =
      patients &&
      patients.length > 0 &&
      patients.map(order => order.property_value);
    const distinctPatientIds = uniqBy(patientIds);

    return (
      <InfoboxOverlay>
        {() => (
          <div className="m-4">
            <div className="flex justify-around items-center">
              <h1 className="text-2xl font-semibold ml-4 w-1/2">
                Upload genotype data
              </h1>
              <div className="w-1/2">
                <UploadForm
                  onFileUploadStarted={this.onFileUploadStarted}
                  onFileUploadedCompleted={this.onFileUploadCompleted}
                  upload={uploadFile}
                  setAlert={this.setAlert}
                />
              </div>
            </div>
            <hr className="my-4 shadow" />
            <div className="px-8">
              <div className="flex">
                <div className="w-1/3 px-4">
                  {distinctPatientIds && distinctPatientIds.length > 0 ? (
                    <div className="bg-gray-100 my-2 px-6 py-4 shadow rounded">
                      <h2 className="mb-2 font-semibold">Uploaded ids:</h2>
                      <hr className="my-4 border-gray-400" />
                      <ul className="list-disc bg-white rounded-lg shadow-inner">
                        {distinctPatientIds.map((element, index) => {
                          return (
                            <li className="ml-8 pl-6 py-2 rounded" key={index}>
                              {element}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <span>Upload files to start.</span>
                  )}
                </div>
                <div className="w-2/3 px-4">
                  {fileList && fileList.length > 0 && (
                    <DataTable
                      headers={this.getHeaders()}
                      data={this.addStatusMessage(fileList)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </InfoboxOverlay>
    );
  }
}

Upload.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  patientIds: PropTypes.array,
  error: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    patientIds: state.upload.data,
    error: state.upload.error
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    uploadFile: apiService.uploadFile,
    addAlert,
    dismissAlert
  })(Upload)
);
