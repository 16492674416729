import React from 'react';
import {createRoot} from 'react-dom/client';
import configureStore from './configureStore';
import Root from './Root';
import settings from './utils/settings';

const store = configureStore();

document.title = settings.getSetting('site_title');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<Root store={store} />);

