import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { CircleQuestion, Logout } from 'grommet-icons';
import settings from '../utils/settings';
import NavItem from '../components/NavItem';
import { get } from '../utils/object';

const mainNav = [
  {
    path: '/',
    label: 'Dashboard'
  },
  {
    label: 'Tools',
    children: [
      {
        path: '/variant-manager',
        label: 'Variant manager'
      },
      {
        path: '/ssup',
        label: 'Self service upload portal'
      }
    ]
  },
  {
    label: 'Help',
    children: [
      {
        // TODO: use correct PDF into public folder
        path: '/AS8_Web_Client_Manual.pdf',
        label: 'Manual',
        external: true
      }
    ]
  },
  {
    label: 'User',
    children: [
      {
        path: '/profile',
        label: 'My profile'
      },
      {
        path: '/logout',
        label: (
          <>
            {'Sign out'}
            <Logout className="stroke-current h-3 w-auto ml-3" />
          </>
        )
      }
    ]
  }
];

export class Header extends Component {
  getLogoPath = () => {
    const fileUrl = get(this.props.file, 'attributes.uri.url', '');

    if (fileUrl && fileUrl.length > 0) {
      // get logo from customer object
      return settings.getSetting('server_root') + '' + fileUrl;
    } else {
      // fallback to local image
      return require('../images/' + settings.getSetting('site_logo'));
    }
  };

  render() {
    const { isLoggedIn, user } = this.props;
    const { pathname } = window.location;
    return (
      <header className="fixed top-0 left-0 right-0 bg-blue-800 text-white z-20" style={{background: '#2c5282'}}>
        <nav className="flex justify-between py-3 px-4 h-16">
          <div className="flex items-center">
            {/* Header Logo */}
            <div className="flex-shrink-0 w-auto h-full bg-white rounded">
              <a href={pathname}>
              <img
                alt={settings.getSetting('site_name')}
                className="h-10 object-cover p-2"
                src={this.getLogoPath()}
              /></a>
            </div>

            {/* Header Title */}
            <div className="items-center ml-2 mr-10 font-bold">
              {settings.getSetting('site_name')}
            </div>

            {/* Main menu */}
            <div className="flex w-full block flex-grow items-center">
              <div>
                <NavItem path="/" label="Dashboard" />
              </div>
              <div>
                <NavItem label="Tools">
                  {mainNav[1].children.map((item, index) => (
                    <NavItem key={index} path={item.path} label={item.label} />
                  ))}
                </NavItem>
              </div>
            </div>
          </div>

          {/* Right menu */}
          <div className="flex items-center">
            {/* Help */}
            <NavItem
              label={<CircleQuestion className="stroke-current" />}
              className="text-center items-center text-xs py-1 px-2 text-white focus:outline-none cursor-pointer hover:text-blue-700"
            >
              {mainNav[2].children.map((item, index) => (
                <NavItem
                  key={index}
                  path={item.path}
                  external={item.external}
                  label={item.label}
                />
              ))}
            </NavItem>

            {/* User information */}
            {isLoggedIn &&
              user.data &&
              user.data.attributes &&
              user.data.attributes.display_name && (
                <div className="flex flex-grow-0 inline-block text-xs items-center text-center">
                  <div className="flex flex-shrink-0 w-8 h-8 rounded bg-gray-400 items-center justify-center font-bold object-cover">
                    {user.data &&
                      user.data.attributes &&
                      user.data.attributes.display_name &&
                      user.data.attributes.display_name
                        .slice(0, 1)
                        .toUpperCase()}
                  </div>
                  <NavItem
                    label={get(
                      user,
                      'data.attributes.display_name',
                      'username'
                    )}
                  >
                    {mainNav[3].children.map((item, index) => (
                      <NavItem
                        key={index}
                        path={item.path}
                        label={item.label}
                      />
                    ))}
                  </NavItem>
                </div>
              )}
          </div>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  file: PropTypes.object
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.user,
    file: state.file.data
  };
};

export default connect(mapStateToProps)(Header);
