import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner';

export class CustomTable extends Component {
  render() {
    const { header, body, size, stripped, loading, isEmpty } = this.props;

    const classnamesHeader = {
      header: classNames(
        'flex justify-between rounded py-4 px-2 bg-gray-100 font-semibold flex-wrap'
      ),
      element: classNames('flex-1 text-center')
    };

    const classnamesBody = {
      row: classNames(
        'flex justify-between shadow-inner bg-gray-100 rounded items-center text-center text-sm hover:text-biologis-blue flex-wrap',
        {
          'even:bg-white': stripped,
          'my-1': !stripped,
          'py-2 text-xs': size === 'xs',
          'py-3 text-sm': size === 'sm',
          'py-4 text-sm': size === 'md'
        }
      ),
      element: classNames('flex-1 truncate p-2')
    };

    return (
      <div className="">
        {/* Header */}
        {header({ classnames: classnamesHeader })}
        <hr className="my-4" />
        {/* Body */}
        {loading && (
          <div className="flex justify-center bg-whiterounded py-4">
            <div className="flex justify-center items-center text-sm px-6 py-2">
              <LoadingSpinner />
              <FormattedMessage id="components.table.loading" />
            </div>
          </div>
        )}
        {isEmpty && !loading && (
          <div className="flex justify-center bg-white rounded py-4">
            <div className="flex justify-center items-center text-sm px-6 py-2">
              <FormattedMessage id="components.table.noItems" />
            </div>
          </div>
        )}
        {body({
          classnames: classnamesBody
        })}
      </div>
    );
  }
}

CustomTable.defaultProps = {
  size: 'sm'
};

CustomTable.propTypes = {
  header: PropTypes.func.isRequired,
  body: PropTypes.func.isRequired,
  size: PropTypes.string,
  stripped: PropTypes.bool,
  pagination: PropTypes.number,
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isFetching: state.genotypes.isFetching
  };
};

export default injectIntl(connect(mapStateToProps, {})(CustomTable));
