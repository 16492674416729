import React from 'react';
import { Component } from 'react';
import styled from '@emotion/styled';

const ErrorBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorMessage = styled.pre`
  font-size: 0.8rem;
  background: #e0e0e0;
  border-radius: 8px;
  padding: 8px;
  font-family: monospace;
`;

const BackToStartButton = styled.a`
  background: #fafafa;
  color: #000;
  border: 1px solid #eee;

  font-size: 1rem;
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background: #eee;
    text-decoration: none;
  }
`;


class ErrorBoundary extends Component {
   state = {
    hasError: false,
    error: undefined,
    errorInfo: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Send this to Sentry\
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ ...this.state, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      const url = window.location.href;
      return (
        <ErrorBox>
          <h1>Oh no, something went wrong...</h1>
          <p style={{ marginBottom: '2em' }}>
            Please let us know so we can fix this!
          </p>
          <ErrorMessage>
            Error message: {this.state?.error?.message}
            <br />
            <h5>{this.state?.errorInfo?.componentStack}</h5>
            Url: {url}
          </ErrorMessage>
          <BackToStartButton href="/">Back to dashboard</BackToStartButton>
        </ErrorBox>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
