import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

export class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { id, placeholder, onChange, options, selected, size } = this.props;

    const classnames = classNames(
      'rounded w-full leading-tight outline-none bg-gray-200 shadow px-2',
      {
        'h-6 text-xs': size === 'xs',
        'h-8 text-sm': size === 'sm',
        'h-10 text-base': size === 'md'
      }
    );

    return (
      <select
        id={id}
        value={selected}
        onChange={e => onChange(e)}
        className={classnames}
      >
        {placeholder && (
          <option key="placeholder" value="">
            {placeholder}
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            return (
              <option
                key={option.key || index}
                value={option.value || option.id}
              >
                {option.label}
              </option>
            );
          })}
      </select>
    );
  }
}

Select.defaultProps = {
  placeholder: '',
  value: '',
  size: 'sm'
};

Select.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  idKey: PropTypes.string,
  idValue: PropTypes.string,
  idLabel: PropTypes.string,
  selected: PropTypes.string,
  size: PropTypes.string
};

export default Select;
