import React, { Component } from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import Button from '../Button';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner';

export class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      maxElementsPerPage: 10
    };
  }

  changePage = page => {
    const { loadData, filter, sort, pager } = this.props;
    if (page >= 0 && page < pager.pages) {
      if (filter) {
        this.setState({ currentPage: page }, () =>
          loadData(filter, page, sort)
        );
      } else {
        this.setState({ currentPage: page }, () => loadData({}, page, sort));
      }
    }
  };

  render() {
    const { pager, children, loading } = this.props;
    const { currentPage } = this.state;
    return (
      <div>
        {children({ currentPage: currentPage, changePage: this.changePage })}
        <hr className="my-4" />
        <div className="flex justify-around items-center py-4 bg-gray-100 rounded">
          <div className="w-16">
            <Button
              size="sm"
              color="secondary"
              onClick={() => this.changePage(0)}
              type="button"
            >
              {'<<'}
            </Button>
          </div>
          <div className="w-16">
            <Button
              size="sm"
              color="secondary"
              onClick={() => this.changePage(currentPage - 1)}
              type="button"
            >
              {'<'}
            </Button>
          </div>
          <div className="text-xs text-center w-64">
            {loading ? (
              <div className="flex justify-center">
                <div className="flex justify-center items-center text-xs">
                  <LoadingSpinner color="black" />
                  <FormattedMessage id="components.table.loading" />
                </div>
              </div>
            ) : (
              <div>{'Page ' + (currentPage + 1) + ' of ' + pager.pages}</div>
            )}
          </div>
          <div className="w-16">
            <Button
              size="sm"
              color="secondary"
              onClick={() => this.changePage(currentPage + 1)}
              type="button"
            >
              {'>'}
            </Button>
          </div>
          <div className="w-16">
            <Button
              size="sm"
              color="secondary"
              onClick={() => this.changePage(pager.pages - 1)}
              type="button"
            >
              {'>>'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Pagination.defaultProps = {
  pager: { pages: 0, count: 0 }
};

Pagination.propTypes = {
  pager: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  filter: PropTypes.object,
  loading: oneOfType([PropTypes.bool, PropTypes.string]),
  sort: PropTypes.array
};

export default injectIntl(Pagination);
