import { SettingsService } from '@brightsolutionsgmbh/client-core';

/**
 * Current settings by host name
 */
const settingsByHost = {
  default: {
    site_name: 'bio.logis Self Upload Portal',
    site_title: 'bio.logis SSUP',
    site_logo: 'bio_Logo_GIMS-4c.jpg',
    client_id: 'd9844058-4140-409d-ad4d-dbfde6b69bf3',
    client_secret: '85de8b8f-48a9-45d9-a002-a7100a9d1fcc',
  },
  localhost: {
    domain: 'https://nginx.qa.biologis-drm.de3.amazee.io',
    site_name: 'GIMS.pharma',
    site_title: 'GIMS.pharma',
  },
  'node.dev.biologis-drm-web.de3.amazee.io': {
    domain: 'https://nginx.dev.biologis-drm.de3.amazee.io',
    site_name: 'GIMS.pharma',
    site_title: 'GIMS.pharma',
  },
  'node.qa.biologis-drm-web.de3.amazee.io': {
    domain: 'https://nginx.qa.biologis-drm.de3.amazee.io',
    site_name: 'GIMS.pharma',
    site_title: 'GIMS.pharma',
  },
  'node.stage.biologis-drm-web.de3.amazee.io': {
    domain: 'https://nginx.stage.biologis-drm.de3.amazee.io',
    site_name: 'GIMS.pharma',
    site_title: 'GIMS.pharma',
  },
  'dev-as8.nauts.eu': {
    site_name: 'dev-as8 SSUP',
    site_title: 'dev-as8 SSUP',
  },
  'biologis-gims-cms.biologisgroup.com': {
    site_name: 'bio.logis Self Upload Portal',
    site_title: 'bio.logis SSUP',
  },
  'ikp-gims-cms.biologisgroup.com': {
    site_name: 'Institute of Clinical Pharmacology Self Upload Portal',
    site_title: 'IKP SSUP',
    site_logo: 'rbk_ikp.svg',
  },
  'uat-gims-cms.biologisgroup.com': {
    site_name: 'UAT Self Upload Portal',
    site_title: 'UAT SSUP',
  },
  'uat2-gims-cms.biologisgroup.com': {
    site_name: 'UAT2 Self Upload Portal',
    site_title: 'UAT2 SSUP',
    site_logo: 'rbk_ikp.svg',
  },
};

/**
 * Get the setting depending on the current host name.
 *
 * @param setting_name
 * @returns {*}
 */
export default new SettingsService(settingsByHost, window.location.hostname);
