import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import { useEffect } from 'react';

export default function Layout() {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [location]);

  return (
        <div
          // style={{
          //   height: '100vh',
          //   display: 'grid',
          //   gridTemplateRows: '55px 1fr',
          // }}
        >
           <Header />
            <main className="pt-16">
              <Outlet />
            </main>
        </div>
  );
}
