import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

export class OperationSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { globalSelect } = this.props;
    if (globalSelect !== prevProps.globalSelect) {
      this.setState({ selected: globalSelect });
    }
  }

  runOperation = (optionId, uuid) => {
    const { options } = this.props;

    this.setState({ selected: optionId });

    options.forEach(option => {
      if (option.id === optionId) {
        return uuid ? option.func(uuid) : option.func();
      }

      if (optionId === '' && option.id === 'default') {
        return uuid ? option.func(uuid) : option.func();
      }
    });
  };

  render() {
    const { target, options, size, color } = this.props;
    const { selected } = this.state;

    const classnames = classNames(
      'rounded w-48 leading-tight outline-none bg-gray-200 shadow px-4',
      {
        'h-6 text-xs': size === 'xs',
        'h-8 text-sm': size === 'sm',
        'h-10 text-base': size === 'md'
      },
      {
        'bg-gray-300 text-gray-900': !color,
        'bg-biologis-blue text-white': color === 'primary'
      }
    );

    return (
      <select
        onChange={e => this.runOperation(e.target.value, target)}
        value={selected}
        className={classnames}
      >
        <option value="">
          {target ? 'Choose operation...' : 'Choose option...'}
        </option>
        {options.map((option, index) => {
          if (option.id !== 'default') {
            return (
              <option key={index} value={target ? option.id : option.value}>
                {option.label}
              </option>
            );
          } else {
            return null;
          }
        })}
      </select>
    );
  }
}

OperationSelect.defaultProps = {
  size: 'xs'
};

OperationSelect.propTypes = {
  target: PropTypes.string,
  options: PropTypes.array.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  globalSelect: PropTypes.string
};

export default OperationSelect;
