import React, { Component } from 'react';
import { connect } from 'react-redux';
import {BrowserRouter, Navigate, Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ScrollToTop from '../../components/ScrollToTop';
import LogIn from './LogIn';
import LogOut from './LogOut';
import { useEffect } from 'react';

import Dashboard from '../Dashboard';
import VariantManager from '../VariantManager';
import SSUP from '../SSUP';
import Profile from '../Profile';
import Layout from '../../layout/Layout';
import ErrorBoundary from '../../components/ErrorBoundary';

class App extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
  };

  render() {
      return <ErrorBoundary>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
          <AppFunc isLoggedIn={this.props.isLoggedIn} />
        </BrowserRouter>
      </ErrorBoundary> 
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  };
};

const AppFunc = ({isLoggedIn}) => { 

    const navigate = useNavigate()
    const location = useLocation().pathname

    useEffect(() => {
      if(!isLoggedIn) {
        navigate('/login')
      }
    }, [isLoggedIn])
  
    return (
     <>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route path="/logout" element={<LogOut />} />

          <Route path="" element={<Navigate to={'/dashboard'} />} />

          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/variant-manager/*" element={<VariantManager />} />
            <Route path="/ssup/*" element={<SSUP />} />
            <Route path="/profile/*" element={<Profile />} />
          </Route>

          <Route path="*" element={<div>Page not found</div>} />
        </Routes>
      </>
    );
}

export default connect(mapStateToProps)(App);
