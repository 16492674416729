export default {
  en: {
    components: {
      table: {
        loading: 'Loading...',
        noItems: 'No items...'
      }
    },
    tools: {
      dashboard: {
        name: 'Dashboard',
        options: {
          genotypePhenotypeWorklist: {
            name: 'Genotype-Phenotype Worklist'
          }
        }
      },
      variantManager: {
        name: 'Variant manager',
        options: {
          search: {
            name: 'Search'
          },
          addVariant: {
            name: 'Add variant'
          },
          editGenotype: {
            name: 'Edit'
          }
        }
      },
      ssup: {
        name: 'Self service upload portal',
        options: {
          upload: {
            name: 'Upload',
            props: {
              upload: {
                header: {
                  name: 'Upload genotype data'
                },
                patientList: {
                  name: 'Patients: '
                },
                uploadedFiles: {
                  name: 'Uploaded files:',
                  names: 'Name',
                  status: 'Status',
                  noFilesUploaded: 'No files uploaded!'
                }
              },
              uploadForm: {
                drag: { name: 'Drop file into this box or ' },
                drop: { name: 'Drop file now to upload!' },
                browse: { name: 'browse...' }
              }
            }
          },
          patients: {
            name: 'Generate',
            props: {
              patientId: {
                name: 'Patient ID'
              },
              requestDate: {
                name: 'Request date'
              },
              reportLanguage: {
                name: 'Report language'
              },
              generateReport: {
                name: 'Generate report'
              },
              loading: {
                name: 'Loading...'
              },
              noItems: {
                name: 'No items...'
              }
            }
          },
          orders: {
            name: 'Finalize',
            props: {
              patientId: { name: 'Patient ID' },
              requestDate: {
                name: 'Request date'
              },
              generationStatus: {
                name: 'Generation status'
              },
              personalization: {
                name: 'Personalization'
              },
              personalizationStatus: {
                name: 'Personalization status'
              },
              report: {
                name: 'Report'
              },
              medicationSafetyCard: {
                name: 'Medication SafetyCard'
              },
              coverLetter: {
                name: 'Cover Letter'
              },
              loading: {
                name: 'Loading...'
              },
              noItems: {
                name: 'No items...'
              },
              generationStatusReady: 'Report generated',
              generationStatusPending: 'Report generation in progress',
              generationStatusReportError: 'Error',
              generationStatusWaitingForInput: 'Waiting for input',
              personalizationStatusReportDelivered: 'Report delivered',
              personalizationStatusPending: 'Report in progress',
              personalizationStatusError: 'Error',
              null: ' '
            }
          },
          save: {
            name: 'Save'
          },
          filter: {
            name: 'Filter'
          },
          reset: {
            name: 'Reset'
          },
          send: {
            name: 'Send'
          },
          download: {
            name: 'Download'
          },
          input: {
            name: 'Input'
          },
          pagination: {
            page: { name: 'Page' },
            total: { name: 'of' }
          },
          proceed: {
            name: 'Proceed'
          }
        }
      }
    },
    settings: {
      name: 'Settings',
      options: {
        account: {
          name: 'Account'
        }
      }
    }
  }
};
