import React, { Component } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types'; // Ensure to import PropTypes
import PageWithNav from '../../components/PageWithNav';
import GenoPhenoWorklist from './GenoPhenoWorklist';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'geno-/phenotypes',
    };
  }

  render() {
    const { intl } = this.props;
    return (
      <PageWithNav
        name={intl.formatMessage({ id: 'tools.dashboard.name' })}
        nav={({ on, router, path, params }) => {
          return <ul>
            <li className="px-4 py-2">
              {on && (
                <Link to={`${path}`}>
                  <FormattedMessage id="tools.dashboard.options.genotypePhenotypeWorklist.name" />
                </Link>
              )}
            </li>
          </ul>
        }}
      >
        {({ router, path, params }) => (
          <Routes>
            <Route path={`/`} element={<GenoPhenoWorklist />} />
          </Routes>
        )}
      </PageWithNav>
    );
  }
}

Dashboard.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(Dashboard);
